import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { NuevoproductoPage } from '../nuevoproducto/nuevoproducto.page';
import { StorageService } from 'src/app/servicios/storage.service';

import { Todo } from '../../../interface/usuario';
import { ProductosService } from 'src/app/servicios/productos.service';
import { EditarproductoPage } from '../editarproducto/editarproducto.page';
import * as moment from 'moment';
import { AmpliacionGarantiaPage } from '../modals/ampliacion-garantia/ampliacion-garantia.page';



@Component({
  selector: 'app-misproductos',
  templateUrl: './misproductos.page.html',
  styleUrls: ['./misproductos.page.scss'],
})
export class MisproductosPage implements OnInit {

  ampliacion_gar = false;

  modelData: any;
  datosusuario: any;
  razonsocial: string;

  public todos: Array<Todo> = [];
  validado: any;
  validar: any;

  copiaArrayArchivos: Todo[] = [];
  misproductos: any;


  shownGroup = null;
  misproductos_CIR: any = [];
  misproductos_CLI: any = [];
  misproductos_ESM: any = [];
  misproductos_LAB: any = [];
  misproductos_UES: any = [];
  misproductos_ASD: any = [];
  misproductos_OSS: any = [];
  misproductos_SPE: any = [];
  vacio: boolean;

  menuproductos: boolean = true;
  menuasistencia: boolean = false;
  menuhistorialfacturas: boolean = false;
  menuhistorialasistenciatecnica: boolean = false;

  respuesta: any;
  shownGroup1: any;
  shownGroup2: any;
  shownGroup3: any;
  shownGroup4: any;
  shownGroup5: any;


  mesesgarantia_ESM: any;
  mesesgarantia_ASD: any;
  fechafactura_ASD: string;
  fechafactura_ESM: string;
  mesesgarantia_CIR: any;
  fechafactura_CIR: string;
  mesesgarantia_CLI: any;
  fechafactura_CLI: string;
  mesesgarantia_LAB: any;
  fechafactura_LAB: string;
  mesesgarantia_UES: any;
  fechafactura_UES: string;
  mesesgarantia_OSS: any;
  fechafactura_OSS: string;

  copiaArrayPromociones: any[];
  i: number;


  clases: any;

  boton2: boolean;
  boton3: boolean;
  boton4: boolean = false;
  copiaArray: any[];
  newarray_ESM: any[];
  newarray_ASD: any[];
  newarray_CIR: any[];
  newarray_CLI: any[];
  newarray_LAB: any[];
  newarray_OSS: any[];
  newarray_SPE: any[];
  newarray_UES: any[];

  fechagarantia_ESM: string;
  fechagarantia_ASD: string;
  fechagarantia_CLI: string;
  fechagarantia_CIR: string;
  fechagarantia_LAB: string;
  fechagarantia_OSS: string;
  fechagarantia_UES: string;
  rol: any;


  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public modalController: ModalController,
    private _storage: StorageService,
    private _misproductos: ProductosService,
    public alertCotroller: AlertController,
    private loadingController: LoadingController

  ) {


  }



  async ngOnInit() {

    //console.log("ngOnInit");

    // console.log("this._storage.val"+this.todos);

    await this._storage.validarusuario().then((result) => {
      this.validado = result;
    });

    await this._storage.validarpermisos().then((result) => {
      this.rol = result;
    });

    await this._storage.validar().then((result) => {
      this.validar = result;
    });

    if (this.validado) {


      if (this.validar == "si") {

        this.todos = await this._storage.read();
        await this.iniciarlistadoproductos();
        await this.listararticulos();

      } else {

        await this._storage.createTodo();
        this.todos = await this._storage.read();
        this.iniciarlistadoproductos();
        await this.listararticulos();

      }


    } else {
      this._storage.val = "no";
      // console.log(this.validado);
      this._storage.cerrarsesion();
      this.navCtrl.navigateRoot('/home');

    }


  }


  async ionViewWillEnter() {

    //console.log("ionViewWillEnter");


    // console.log("this._storage.val"+this.todos);
    document.getElementById("principiopagina").focus();

    await this._storage.validarusuario().then((result) => {
      this.validado = result;
    });

    await this._storage.validar().then((result) => {
      this.validar = result;
    });

    if (this.validado) {


      if (this.validar == "si") {

        this.todos = await this._storage.read();
        await this.iniciarlistadoproductos();
        await this.listararticulos();

      } else {

        await this._storage.createTodo();
        this.todos = await this._storage.read();
        this.iniciarlistadoproductos();
        await this.listararticulos();

      }


    } else {
      this._storage.val = "no";
      // console.log(this.validado);
      this._storage.cerrarsesion();
      this.navCtrl.navigateRoot('/home');

    }





  }


  async ionViewDidLeave() {
    //console.log("ionViewDidLeave");

  }

  async ionViewWillLeave() {
    //console.log("ionViewWillLeave");

  }


  gestionarDesplegables(categoria, index, estado) {
    for (let i = 0; i < this.newarray_CIR.length; i++) {
      this.newarray_CIR[i].desplegable = false;
    }
    for (let i = 0; i < this.newarray_CLI.length; i++) {
      this.newarray_CLI[i].desplegable = false;
    }
    for (let i = 0; i < this.newarray_ESM.length; i++) {
      this.newarray_ESM[i].desplegable = false;
    }
    for (let i = 0; i < this.newarray_LAB.length; i++) {
      this.newarray_LAB[i].desplegable = false;
    }
    for (let i = 0; i < this.newarray_OSS.length; i++) {
      this.newarray_OSS[i].desplegable = false;
    }
    for (let i = 0; i < this.newarray_UES.length; i++) {
      this.newarray_UES[i].desplegable = false;
    }
    switch (categoria) {
      case "CIR":
        this.newarray_CIR[index].desplegable = !estado;
        break;
      case "CLI":
        this.newarray_CLI[index].desplegable = !estado;
        break;
      case "ESM":
        this.newarray_ESM[index].desplegable = !estado;
        break;
      case "LAB":
        this.newarray_LAB[index].desplegable = !estado;
        break;
      case "OSS":
        this.newarray_OSS[index].desplegable = !estado;
        break;
      case "UES":
        this.newarray_UES[index].desplegable = !estado;
        break;
      default:
        break;
    }
  }

  // toggleGroup(group) {
  //     if (this.isGroupShown(group)) {
  //         this.shownGroup = null;
  //     } else {
  //         this.shownGroup = group;
  //     }
  // };

  // isGroupShown(group) {
  //     return this.shownGroup === group;
  // };


  // toggleGroup1(group1) {

  //   if (this.isGroupShown1(group1)) {
  //       this.shownGroup1 = null;
  //   } else {
  //       this.shownGroup1 = group1;
  //   }
  // };

  // isGroupShown1(group1) {
  //   return this.shownGroup1 === group1;
  // };


  // toggleGroup2(group2) {
  //   if (this.isGroupShown2(group2)) {
  //       this.shownGroup2 = null;
  //   } else {
  //       this.shownGroup2 = group2;
  //       this.isGroupShown1 === group2;
  //       //console.log(this.isGroupShown1 (group2));
  //   }
  // };

  // isGroupShown2(group2) {
  //   return this.shownGroup2 === group2;
  // };



  // toggleGroup3(group3) {
  //   if (this.isGroupShown3(group3)) {
  //       this.shownGroup3 = null;
  //   } else {
  //       this.shownGroup3 = group3;
  //   }
  // };

  // isGroupShown3(group3) {
  //   return this.shownGroup3 === group3;
  // };



  // toggleGroup4(group4) {
  //   if (this.isGroupShown4(group4)) {
  //       this.shownGroup4 = null;
  //   } else {
  //       this.shownGroup4 = group4;
  //   }
  // };

  // isGroupShown4(group4) {
  //   return this.shownGroup4 === group4;
  // };



  // toggleGroup5(group5) {
  //   if (this.isGroupShown5(group5)) {
  //       this.shownGroup5 = null;
  //   } else {
  //       this.shownGroup5 = group5;
  //   }
  // };

  // isGroupShown5(group5) {
  //   return this.shownGroup5 === group5;
  // };

  // INICIO METODOS GARANTIA

  calcularOpcionesGarantia(fecha_inicial, Optar_Gar1, Optar_Gar2, Optar_Gar3, GarantiaVigente, FechaFinGarantia) {
    let fecha_ini = moment(fecha_inicial).format("YYYY-MM-DD"); // FACTURA INICIAL
    let fecha_fin = moment(FechaFinGarantia).format("YYYY-MM-DD"); // FIN GARANTIA
    let fecha_tope = moment(moment(fecha_ini).add(60, 'M')).format("YYYY-MM-DD"); // = fecha_ini + 60
    let fecha_Actual = moment().format("YYYY-MM-DD"); // = moment(now)
    let Opciones_Garantia = {
      "Gar1": 0,
      "Gar2": 0,
      "Gar3": 0,
    };
    if (!(GarantiaVigente || (fecha_fin < fecha_Actual))) {
      if (Number(Optar_Gar1)) {
        Opciones_Garantia.Gar1 = (moment(moment(fecha_fin).add(12, 'M')).format("YYYY-MM-DD") <= fecha_tope) ? 1 : 0;
      } else {
        Opciones_Garantia.Gar1 = 0;
      }
      if (Number(Optar_Gar2)) {
        Opciones_Garantia.Gar2 = (moment(moment(fecha_fin).add(24, 'M')).format("YYYY-MM-DD") <= fecha_tope) ? 1 : 0;
      } else {
        Opciones_Garantia.Gar2 = 0;
      }
      if (Number(Optar_Gar3)) {
        Opciones_Garantia.Gar3 = (moment(moment(fecha_fin).add(36, 'M')).format("YYYY-MM-DD") <= fecha_tope) ? 1 : 0;
      } else {
        Opciones_Garantia.Gar3 = 0;
      }
    }
    return Opciones_Garantia;
  }

  calcularOptarGarantia(array) {
    let aux = false;
    Object.values(array).forEach((key) => {
      if (key === 1) {
        aux = true;
        return;
      }
    });
    return aux;
  }


  async modalAmpliacionGarantia(array_producto, categoria) {
    array_producto.categoria = categoria;
    const modal = await this.modalController.create({
      component: AmpliacionGarantiaPage,
      cssClass: 'ampliacionGarantia',
      componentProps: {
        'articulo': array_producto,
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        //console.log('Modal Data : ', modelData.data);
      }
    });

    return await modal.present();
  }

  // TERMINA METODOS GARANTIA


  calcularFecha(fecha_ini, meses) {
    return moment(fecha_ini).add(meses, 'M').format('DD-MM-YYYY');
  }

  async listararticulos() {


    await this._misproductos.listararticuloscliente().subscribe((ans: []) => {


      this.respuesta = ans;


      this.copiaArrayArchivos = [];
      this.copiaArrayArchivos.push(... this.respuesta);

      //console.log("listar articulos" + JSON.stringify(this.respuesta));


    });


  }


  calcularTextoFechaDuracionGarantia(meses) {
    switch (meses) {
      case "24":
        return "+2 Años Cliente"
      case "36":
        return "+3 Años Cliente"
      default:
        return "+1 Años Cliente"
    }
  }

  async iniciarlistadoproductos() {

    let cif = this.todos[0]['CIFDNI'];



    await this._misproductos.listarpromociones().subscribe(async (ans) => {


      this.copiaArrayPromociones = [];
      this.copiaArrayPromociones.push(...ans['promociones']);

      // console.log(this.copiaArrayPromociones);


    });



    await this._misproductos.listarproductoscliente(cif).subscribe(async (ans = []) => {   // EL ORIGINAL


      //  this.misproductos_CIR = ans['CIR'];
      //  this.misproductos_CLI = ans['CLI'];
      // //  this.misproductos_ESM = ans['ESM'];
      //  this.misproductos_LAB = ans['LAB'];
      //  this.misproductos_UES = ans['UES'];
      //  this.misproductos_OSS = ans['OSS'];




      //this.misproductos_ASD = ans['ASD']; //no tiene que aparecer en el listado
      //this.misproductos_SPE = ans['SPE']; // no tiene que aparecer en el listado


      //console.log("CIR"+JSON.stringify(this.misproductos_CIR));
      // console.log("ANS" + JSON.stringify(ans));
      // console.log("misproductos_ESM"+JSON.stringify(this.misproductos_ESM));
      //  console.log("misproductos_LAB"+JSON.stringify(this.misproductos_LAB));
      //  console.log("misproductos_UES"+JSON.stringify(this.misproductos_UES));
      //  console.log("misproductos_OSS"+JSON.stringify(this.misproductos_OSS));



      //console.log(this.misproductos_CLI);
      //console.log(this.misproductos_CLI[0]['fecha']);
      //  console.log("visiblegarantia"+this.visiblegarantia);

      this.copiaArray = [];
      this.newarray_ESM = [];
      this.newarray_CIR = [];
      this.newarray_CLI = [];
      this.newarray_LAB = [];
      this.newarray_OSS = [];
      this.newarray_UES = [];
      this.copiaArray.push(ans);

      this.fechafactura_CLI = moment("20211031", "YYYYMMDD").fromNow();
      this.fechafactura_CIR = moment("20211031", "YYYYMMDD").fromNow();
      this.fechafactura_ESM = moment("20211031", "YYYYMMDD").fromNow();
      this.fechafactura_LAB = moment("20211031", "YYYYMMDD").fromNow();
      this.fechafactura_UES = moment("20211031", "YYYYMMDD").fromNow();
      this.fechafactura_OSS = moment("20211031", "YYYYMMDD").fromNow();


      //  console.log("this.fechafactura_ESM"+this.fechafactura_ESM);



      this.copiaArray.forEach(async element => {


        this.misproductos_ESM = element;
        this.misproductos_CIR = element;
        this.misproductos_CLI = element;
        this.misproductos_LAB = element;
        this.misproductos_OSS = element;
        this.misproductos_UES = element;



        // CIR: []
        // CLI: [{…}]
        // ESM: (3) [{…}, {…}, {…}]
        // LAB: []
        // OSS: []
        // UES



        if (element['CIR'] != "") {

          // this.mesesgarantia_CIR = this.misproductos_CIR[0]['MesesGarantiaVenta'];
          // this.fechafactura_CIR = this.misproductos_CIR[0]['fecha'];
          // this.fechafactura_CIR = moment(this.fechafactura_CIR).add(this.mesesgarantia_CIR, 'months');



          // console.log("this.fechafactura_CIR"+this.fechafactura_CIR);
          // console.log("this.mesesgarantia_CIR"+this.mesesgarantia_CIR);
          // console.log("this.misproductos_CIR[0]['fecha']"+this.misproductos_CIR[0]['fecha']);


          for (let index = 0; index < element['CIR'].length; index++) {



            //console.log(element['CIR'][index]['fecha']);
            //console.log(element['CIR'][index]);

            this.mesesgarantia_CIR = element['CIR'][index]['MesesGarantiaVenta'];
            this.fechafactura_CIR = moment(element['CIR'][index]['fecha']).format('YYYY-MM-DD');
            this.fechafactura_CIR = moment(this.fechafactura_CIR).add(this.mesesgarantia_CIR, 'M').format('YYYY-MM-DD');

            this.boton2 = true;
            this.boton3 = false;

            // console.log(this.copiaArrayPromociones);
            // console.log( element['CIR'][index]['CodigoArticulo']);
            // console.log( element['CIR'][index]);
            // console.log(this.fechafactura_CIR);
            // console.log(this.mesesgarantia_CIR);


            this.fechagarantia_CIR = "";
            this.copiaArrayPromociones.forEach(promocion => {


              if (promocion['CodigoArticulo'] === element['CIR'][index]['CodigoArticulo']) {
                // console.log("promocion"+JSON.stringify(promocion['FechaInicioPromocionGarantia12']));
                // console.log(element['CIR'][index]['fecha']);
                // console.log(element['CIR'][index]['WehadentMesesGarantiaPromo']);


                var time1 = moment(element['CIR'][index]['Fecha']).format('YYYY-MM-DD');
                var timeinicio = moment(promocion['FechaInicioPromocionGarantia12']).format('YYYY-MM-DD');
                var timefinal = moment(promocion['FechaFinPromocionGarantia12']).format('YYYY-MM-DD');

                // console.log('está entre estas dos fechas');
                // console.log("si están entre estas dos fechas"+time1);
                // console.log("si están entre estas dos fechas"+ timeinicio);
                // console.log("is están entre estas dos fechas"+timefinal);

                if (time1 >= timeinicio && time1 <= timefinal) {

                  this.fechagarantia_CIR = moment(this.fechafactura_CIR).add(element['CIR'][index]['WehadentMesesGarantiaPromo'], 'M').format('YYYY-MM-DD');
                  //console.log('y coincide entre las dos fechas'+this.fechagarantia_CIR);
                  this.boton2 = false;
                  this.boton3 = true;
                  this.clases = "fondoverde";

                }



              } else {
                //this.fechafactura_CIR = moment("12-25-2021", "MM-DD-YYYY").fromNow();
                //this.boton2= true;
                //this.boton3 = false;

              }






            });

            // alert(this.boton2);
            // alert(this.boton3);
            // if (this.misproductos_CIR['CIR'][index]['WehadentPrecioGarantia24Meses']) {}
            // if (this.misproductos_CIR['CIR'][index]['WehadentPrecioGarantia12Meses']) {}
            // if (this.misproductos_CIR['CIR'][index]['WehadentPrecioGarantia36Meses']) {}
            // this.misproductos_CIR['CIR'][index]['factura']
            // this.fechafactura_CIR
            // this.fechagarantia_CIR
            // this.misproductos_CIR['CIR'][index]['factura']
            // this.fechafactura_CIR
            // this.fechagarantia_CIR
            // VALIDARGAR( meses )
            // RETURN



            // let fecha_ini = moment(this.misproductos_CIR['CIR'][index]['factura']).format("YYYY-MM-DD"); // FACTURA INICIAL
            // let fecha_tope = moment(moment(fecha_ini).add(60, 'M')).toDate(); // = fecha_ini + 60
            // let fecha_Actual = moment().format("YYYY-MM-DD"); // = moment(now)
            // //   gar1, gar2, gar3, gar1-2, gar1-3, gar2-3, gar1-2-3
            // // [ 1, 1, 1, 0 , 1 , 0, 0]
            // let Opciones_Garantia = {
            //   "Gar1": 0,
            //   "Gar2": 0,
            //   "Gar3": 0,
            //   "Gar1-2": 0,
            //   "Gar1-3": 0,
            //   "Gar2-3": 0,
            //   // "Gar1-2-3": 0,
            // };
            // Opciones_Garantia.Gar1 = ( moment(moment(fecha_Actual).add(12, 'M')).toDate() <= fecha_tope) ? 1 : 0;
            // Opciones_Garantia.Gar2 = ( moment(moment(fecha_Actual).add(24, 'M')).toDate() <= fecha_tope) ? 1 : 0;
            // Opciones_Garantia.Gar3 = ( moment(moment(fecha_Actual).add(36, 'M')).toDate() <= fecha_tope) ? 1 : 0;
            // Opciones_Garantia['Gar1-2'] = ( Opciones_Garantia.Gar3 === 1 ) ? 1 : 0;
            // Opciones_Garantia['Gar1-3'] = ( moment(moment(fecha_Actual).add(48, 'M')).toDate() <= fecha_tope) ? 1 : 0;
            // Opciones_Garantia['Gar2-3'] = ( moment(moment(fecha_Actual).add(60, 'M')).toDate() <= fecha_tope) ? 1 : 0;

            // IF BOTON2
            // IF  BOTON 3
            // fecha boton3 + this.fechagarantia_CIR =< fecha tope
            // ELSE
            // fecha boton2 + this.fechagarantia_CIR =< fecha tope

            // ELSE
            // FECHA ACTUAL + this.fechagarantia_CIR =< fecha tope

            // this.misproductos_CIR['CIR'][index]['factura'] + 60 meses = FACTURA FINAL



            let array_CIR = [{
              CIFDNI: this.misproductos_CIR['CIR'][index]['CIFDNI'],
              CodigoArticulo: this.misproductos_CIR['CIR'][index]['CodigoArticulo'],
              CodigoFamilia: this.misproductos_CIR['CIR'][index]['CodigoFamilia'],
              DescripcionArticulo: this.misproductos_CIR['CIR'][index]['DescripcionArticulo'],
              MesesGarantiaVenta: this.misproductos_CIR['CIR'][index]['MesesGarantiaVenta'],
              NumeroSerie: this.misproductos_CIR['CIR'][index]['NumeroSerie'],
              Referencia: this.misproductos_CIR['CIR'][index]['Referencia'],
              WehadentMesesGarantiaPromo: this.misproductos_CIR['CIR'][index]['WehadentMesesGarantiaPromo'],
              WehadentPrecioGarantia12Meses: this.misproductos_CIR['CIR'][index]['WehadentPrecioGarantia12Meses'],
              WehadentPrecioGarantia24Meses: this.misproductos_CIR['CIR'][index]['WehadentPrecioGarantia24Meses'],
              WehadentPrecioGarantia36Meses: this.misproductos_CIR['CIR'][index]['WehadentPrecioGarantia36Meses'],
              estado: this.misproductos_CIR['CIR'][index]['estado'],
              factura: this.misproductos_CIR['CIR'][index]['factura'],
              fecha: this.misproductos_CIR['CIR'][index]['fecha'],
              id: this.misproductos_CIR['CIR'][index]['id'],
              ceco: this.misproductos_OSS['CIR'][index]['ceco'],
              fechagarantia: this.fechafactura_CIR,
              fechagarantia2: this.fechagarantia_CIR,
              boton2: this.boton2,
              boton3: this.boton3,
              boton4: this.boton4,
              desplegable: false,
              ampliacion_gar: false,
              Opciones_gar: {},
            }];
            await this._misproductos.shopproducts(cif).subscribe(async (test: any) => {
              if (test.length) {
                for (let pos = 0; pos < test.length; pos++) {
                  let GarantiaVigente = false;
                  if (array_CIR[0]["Referencia"] == test[pos]['Referencia']) { GarantiaVigente = true };
                  let fecha_comprobaciones = (array_CIR[0].fechagarantia2) ? array_CIR[0].fechagarantia2 : array_CIR[0].fechagarantia;
                  array_CIR[0].Opciones_gar = this.calcularOpcionesGarantia(array_CIR[0].fecha, array_CIR[0].WehadentPrecioGarantia12Meses, array_CIR[0].WehadentPrecioGarantia24Meses, array_CIR[0].WehadentPrecioGarantia36Meses, GarantiaVigente, fecha_comprobaciones);
                  array_CIR[0].ampliacion_gar = this.calcularOptarGarantia(array_CIR[0].Opciones_gar);
                  if (GarantiaVigente) {
                    array_CIR[0]["TipoGar"] = test[pos]['TipoGar'];
                    array_CIR[0]["FechaCompraGarantia"] = test[pos]['FechaCompraGarantia'];
                    array_CIR[0].boton4 = true;
                    pos = test.length;
                  }
                }
              } else {
                //console.log("HOLA");
                let fecha_comprobaciones = (array_CIR[0].fechagarantia2) ? array_CIR[0].fechagarantia2 : array_CIR[0].fechagarantia;
                array_CIR[0].Opciones_gar = this.calcularOpcionesGarantia(array_CIR[0].fecha, array_CIR[0].WehadentPrecioGarantia12Meses, array_CIR[0].WehadentPrecioGarantia24Meses, array_CIR[0].WehadentPrecioGarantia36Meses, false, fecha_comprobaciones);
                array_CIR[0].ampliacion_gar = this.calcularOptarGarantia(array_CIR[0].Opciones_gar);
              }
            });


            this.newarray_CIR.push(...array_CIR);

            //console.log(this.newarray_CIR);


          }//acaba el for


        }/// acaba el elent CIR

        if (element['CLI'] != "") {

          // this.mesesgarantia_CLI = this.misproductos_CLI[0]['MesesGarantiaVenta'];
          // this.fechafactura_CLI = this.misproductos_CLI[0]['fecha'];
          // this.fechafactura_CLI = moment(this.fechafactura_CLI).add(this.mesesgarantia_CLI, 'months');



          // console.log("this.fechafactura_CLI"+this.fechafactura_CLI);
          // console.log("this.mesesgarantia_CLI"+this.mesesgarantia_CLI);
          // console.log("this.misproductos_CLI[0]['fecha']"+this.misproductos_CLI[0]['fecha']);


          for (let index = 0; index < element['CLI'].length; index++) {



            //console.log(element['CLI'][index]['fecha']);
            //console.log(element['CLI'][index]);

            this.mesesgarantia_CLI = element['CLI'][index]['MesesGarantiaVenta'];
            this.fechafactura_CLI = moment(element['CLI'][index]['fecha']).format('YYYY-MM-DD');
            this.fechafactura_CLI = moment(this.fechafactura_CLI).add(this.mesesgarantia_CLI, 'M').format('YYYY-MM-DD');

            this.boton2 = true;
            this.boton3 = false;

            // console.log(this.copiaArrayPromociones);
            // console.log( element['CLI'][index]['CodigoArticulo']);
            // console.log( element['CLI'][index]);
            // console.log(this.fechafactura_CLI);
            // console.log(this.mesesgarantia_CLI);


            this.fechagarantia_CLI = "";
            this.copiaArrayPromociones.forEach(promocion => {


              if (promocion['CodigoArticulo'] === element['CLI'][index]['CodigoArticulo']) {
                // console.log("promocion"+JSON.stringify(promocion['FechaInicioPromocionGarantia12']));
                // console.log(element['CLI'][index]['fecha']);
                // console.log(element['CLI'][index]['WehadentMesesGarantiaPromo']);


                var time1 = moment(element['CLI'][index]['Fecha']).format('YYYY-MM-DD');
                var timeinicio = moment(promocion['FechaInicioPromocionGarantia12']).format('YYYY-MM-DD');
                var timefinal = moment(promocion['FechaFinPromocionGarantia12']).format('YYYY-MM-DD');

                // console.log('está entre estas dos fechas');
                // console.log("si están entre estas dos fechas"+time1);
                // console.log("si están entre estas dos fechas"+ timeinicio);
                // console.log("is están entre estas dos fechas"+timefinal);

                if (time1 >= timeinicio && time1 <= timefinal) {

                  this.fechagarantia_CLI = moment(this.fechafactura_CLI).add(element['CLI'][index]['WehadentMesesGarantiaPromo'], 'M').format('YYYY-MM-DD');
                  //console.log('y coincide entre las dos fechas'+this.fechagarantia_CLI);
                  this.boton2 = false;
                  this.boton3 = true;
                  this.clases = "fondoverde";

                }



              } else {
                //this.fechafactura_CLI = moment("12-25-2021", "MM-DD-YYYY").fromNow();
                //this.boton2= true;
                //this.boton3 = false;

              }






            });

            // alert(this.boton2);
            // alert(this.boton3);

            let array_CLI = [{
              CIFDNI: this.misproductos_CLI['CLI'][index]['CIFDNI'],
              CodigoArticulo: this.misproductos_CLI['CLI'][index]['CodigoArticulo'],
              CodigoFamilia: this.misproductos_CLI['CLI'][index]['CodigoFamilia'],
              DescripcionArticulo: this.misproductos_CLI['CLI'][index]['DescripcionArticulo'],
              MesesGarantiaVenta: this.misproductos_CLI['CLI'][index]['MesesGarantiaVenta'],
              NumeroSerie: this.misproductos_CLI['CLI'][index]['NumeroSerie'],
              Referencia: this.misproductos_CLI['CLI'][index]['Referencia'],
              WehadentMesesGarantiaPromo: this.misproductos_CLI['CLI'][index]['WehadentMesesGarantiaPromo'],
              WehadentPrecioGarantia12Meses: this.misproductos_CLI['CLI'][index]['WehadentPrecioGarantia12Meses'],
              WehadentPrecioGarantia24Meses: this.misproductos_CLI['CLI'][index]['WehadentPrecioGarantia24Meses'],
              WehadentPrecioGarantia36Meses: this.misproductos_CLI['CLI'][index]['WehadentPrecioGarantia36Meses'],
              estado: this.misproductos_CLI['CLI'][index]['estado'],
              factura: this.misproductos_CLI['CLI'][index]['factura'],
              fecha: this.misproductos_CLI['CLI'][index]['fecha'],
              id: this.misproductos_CLI['CLI'][index]['id'],
              ceco: this.misproductos_OSS['CLI'][index]['ceco'],
              fechagarantia: this.fechafactura_CLI,
              fechagarantia2: this.fechagarantia_CLI,
              boton2: this.boton2,
              boton3: this.boton3,
              boton4: this.boton4,
              desplegable: false,
              ampliacion_gar: false,
              Opciones_gar: {},
            }];
            await this._misproductos.shopproducts(cif).subscribe(async (test: any) => {
              if (test.length) {
                for (let pos = 0; pos < test.length; pos++) {
                  let GarantiaVigente = false;
                  if (array_CLI[0]["Referencia"] == test[pos]['Referencia']) { GarantiaVigente = true };
                  let fecha_comprobaciones = (array_CLI[0].fechagarantia2) ? array_CLI[0].fechagarantia2 : array_CLI[0].fechagarantia;
                  array_CLI[0].Opciones_gar = this.calcularOpcionesGarantia(array_CLI[0].fecha, array_CLI[0].WehadentPrecioGarantia12Meses, array_CLI[0].WehadentPrecioGarantia24Meses, array_CLI[0].WehadentPrecioGarantia36Meses, GarantiaVigente, fecha_comprobaciones);
                  array_CLI[0].ampliacion_gar = this.calcularOptarGarantia(array_CLI[0].Opciones_gar);
                  if (GarantiaVigente) {
                    array_CLI[0]["TipoGar"] = test[pos]['TipoGar'];
                    array_CLI[0]["FechaCompraGarantia"] = test[pos]['FechaCompraGarantia'];
                    array_CLI[0].boton4 = true;
                    pos = test.length;
                  }
                }
              } else {
                let fecha_comprobaciones = (array_CLI[0].fechagarantia2) ? array_CLI[0].fechagarantia2 : array_CLI[0].fechagarantia;
                array_CLI[0].Opciones_gar = this.calcularOpcionesGarantia(array_CLI[0].fecha, array_CLI[0].WehadentPrecioGarantia12Meses, array_CLI[0].WehadentPrecioGarantia24Meses, array_CLI[0].WehadentPrecioGarantia36Meses, false, fecha_comprobaciones);
                array_CLI[0].ampliacion_gar = this.calcularOptarGarantia(array_CLI[0].Opciones_gar);
              }
            });



            this.newarray_CLI.push(...array_CLI);

            // console.log(this.newarray_CLI);


          }//acaba el for


        }/// acaba el elent CLI

        if (element['ESM'] != "") {

          // this.mesesgarantia_ESM = this.misproductos_ESM[0]['MesesGarantiaVenta'];
          // this.fechafactura_ESM = this.misproductos_ESM[0]['fecha'];
          // this.fechafactura_ESM = moment(this.fechafactura_ESM).add(this.mesesgarantia_ESM, 'months');



          // console.log("this.fechafactura_ESM"+this.fechafactura_ESM);
          // console.log("this.mesesgarantia_ESM"+this.mesesgarantia_ESM);
          // console.log("this.misproductos_ESM[0]['fecha']"+this.misproductos_ESM[0]['fecha']);


          for (let index = 0; index < element['ESM'].length; index++) {



            //console.log(element['ESM'][index]['fecha']);
            //console.log(element['ESM'][index]);

            this.mesesgarantia_ESM = element['ESM'][index]['MesesGarantiaVenta'];
            this.fechafactura_ESM = moment(element['ESM'][index]['fecha']).format('YYYY-MM-DD');
            this.fechafactura_ESM = moment(this.fechafactura_ESM).add(this.mesesgarantia_ESM, 'M').format('YYYY-MM-DD');

            this.boton2 = true;
            this.boton3 = false;

            // console.log(this.copiaArrayPromociones);
            // console.log( element['ESM'][index]['CodigoArticulo']);
            // console.log( element['ESM'][index]);
            // console.log(this.fechafactura_ESM);
            // console.log(this.mesesgarantia_ESM);


            this.fechagarantia_ESM = "";
            this.copiaArrayPromociones.forEach(promocion => {


              if (promocion['CodigoArticulo'] === element['ESM'][index]['CodigoArticulo']) {
                // console.log("promocion"+JSON.stringify(promocion['FechaInicioPromocionGarantia12']));
                // console.log(element['ESM'][index]['fecha']);
                // console.log(element['ESM'][index]['WehadentMesesGarantiaPromo']);


                var time1 = moment(element['ESM'][index]['Fecha']).format('YYYY-MM-DD');
                var timeinicio = moment(promocion['FechaInicioPromocionGarantia12']).format('YYYY-MM-DD');
                var timefinal = moment(promocion['FechaFinPromocionGarantia12']).format('YYYY-MM-DD');

                // console.log('está entre estas dos fechas');
                // console.log("si están entre estas dos fechas"+time1);
                // console.log("si están entre estas dos fechas"+ timeinicio);
                // console.log("is están entre estas dos fechas"+timefinal);

                if (time1 >= timeinicio && time1 <= timefinal) {

                  this.fechagarantia_ESM = moment(this.fechafactura_ESM).add(element['ESM'][index]['WehadentMesesGarantiaPromo'], 'M').format('YYYY-MM-DD');
                  //console.log('y coincide entre las dos fechas'+this.fechagarantia_ESM);
                  this.boton2 = false;
                  this.boton3 = true;
                  this.clases = "fondoverde";

                }



              } else {
                //this.fechafactura_ESM = moment("12-25-2021", "MM-DD-YYYY").fromNow();
                //this.boton2= true;
                //this.boton3 = false;

              }






            });

            // alert(this.boton2);
            // alert(this.boton3);

            let array_ESM = [{
              CIFDNI: this.misproductos_ESM['ESM'][index]['CIFDNI'],
              CodigoArticulo: this.misproductos_ESM['ESM'][index]['CodigoArticulo'],
              CodigoFamilia: this.misproductos_ESM['ESM'][index]['CodigoFamilia'],
              DescripcionArticulo: this.misproductos_ESM['ESM'][index]['DescripcionArticulo'],
              MesesGarantiaVenta: this.misproductos_ESM['ESM'][index]['MesesGarantiaVenta'],
              NumeroSerie: this.misproductos_ESM['ESM'][index]['NumeroSerie'],
              Referencia: this.misproductos_ESM['ESM'][index]['Referencia'],
              WehadentMesesGarantiaPromo: this.misproductos_ESM['ESM'][index]['WehadentMesesGarantiaPromo'],
              WehadentPrecioGarantia12Meses: this.misproductos_ESM['ESM'][index]['WehadentPrecioGarantia12Meses'],
              WehadentPrecioGarantia24Meses: this.misproductos_ESM['ESM'][index]['WehadentPrecioGarantia24Meses'],
              WehadentPrecioGarantia36Meses: this.misproductos_ESM['ESM'][index]['WehadentPrecioGarantia36Meses'],
              estado: this.misproductos_ESM['ESM'][index]['estado'],
              factura: this.misproductos_ESM['ESM'][index]['factura'],
              fecha: this.misproductos_ESM['ESM'][index]['fecha'],
              id: this.misproductos_ESM['ESM'][index]['id'],
              ceco: this.misproductos_OSS['ESM'][index]['ceco'],
              fechagarantia: this.fechafactura_ESM,
              fechagarantia2: this.fechagarantia_ESM,
              boton2: this.boton2,
              boton3: this.boton3,
              boton4: this.boton4,
              desplegable: false,
              ampliacion_gar: false,
              Opciones_gar: {},
            }];
            //console.log(array_ESM[0]);
            await this._misproductos.shopproducts(cif).subscribe(async (test: any) => {
              if (test.length) {
                for (let pos = 0; pos < test.length; pos++) {
                  let GarantiaVigente = false;
                  if (array_ESM[0]["Referencia"] == test[pos]['Referencia']) { GarantiaVigente = true };
                  //console.log(array_ESM[0].fechagarantia2);
                  let fecha_comprobaciones = (array_ESM[0].fechagarantia2) ? array_ESM[0].fechagarantia2 : array_ESM[0].fechagarantia;
                  array_ESM[0].Opciones_gar = this.calcularOpcionesGarantia(array_ESM[0].fecha, array_ESM[0].WehadentPrecioGarantia12Meses, array_ESM[0].WehadentPrecioGarantia24Meses, array_ESM[0].WehadentPrecioGarantia36Meses, GarantiaVigente, fecha_comprobaciones);
                  array_ESM[0].ampliacion_gar = this.calcularOptarGarantia(array_ESM[0].Opciones_gar);
                  if (GarantiaVigente) {
                    array_ESM[0]["TipoGar"] = test[pos]['TipoGar'];
                    array_ESM[0]["FechaCompraGarantia"] = test[pos]['FechaCompraGarantia'];
                    array_ESM[0].boton4 = true;
                    pos = test.length;
                  }
                }
              } else {
                let fecha_comprobaciones = (array_ESM[0].fechagarantia2) ? array_ESM[0].fechagarantia2 : array_ESM[0].fechagarantia;
                array_ESM[0].Opciones_gar = this.calcularOpcionesGarantia(array_ESM[0].fecha, array_ESM[0].WehadentPrecioGarantia12Meses, array_ESM[0].WehadentPrecioGarantia24Meses, array_ESM[0].WehadentPrecioGarantia36Meses, false, fecha_comprobaciones);
                array_ESM[0].ampliacion_gar = this.calcularOptarGarantia(array_ESM[0].Opciones_gar);
              }
              //PRUEBA
            });
            this.newarray_ESM.push(...array_ESM);

            //console.log(this.newarray_ESM);


          }//acaba el for


        }

        if (element['LAB'] != "") {

          // this.mesesgarantia_LAB = this.misproductos_LAB[0]['MesesGarantiaVenta'];
          // this.fechafactura_LAB = this.misproductos_LAB[0]['fecha'];
          // this.fechafactura_LAB = moment(this.fechafactura_LAB).add(this.mesesgarantia_LAB, 'months');



          // console.log("this.fechafactura_LAB"+this.fechafactura_LAB);
          // console.log("this.mesesgarantia_LAB"+this.mesesgarantia_LAB);
          // console.log("this.misproductos_LAB[0]['fecha']"+this.misproductos_LAB[0]['fecha']);


          for (let index = 0; index < element['LAB'].length; index++) {



            //console.log(element['LAB'][index]['fecha']);
            //console.log(element['LAB'][index]);

            this.mesesgarantia_LAB = element['LAB'][index]['MesesGarantiaVenta'];
            this.fechafactura_LAB = moment(element['LAB'][index]['fecha']).format('YYYY-MM-DD');
            this.fechafactura_LAB = moment(this.fechafactura_LAB).add(this.mesesgarantia_LAB, 'M').format('YYYY-MM-DD');

            this.boton2 = true;
            this.boton3 = false;

            // console.log(this.copiaArrayPromociones);
            // console.log( element['LAB'][index]['CodigoArticulo']);
            // console.log( element['LAB'][index]);
            // console.log(this.fechafactura_LAB);
            // console.log(this.mesesgarantia_LAB);


            this.fechagarantia_LAB = "";
            this.copiaArrayPromociones.forEach(promocion => {


              if (promocion['CodigoArticulo'] === element['LAB'][index]['CodigoArticulo']) {
                // console.log("promocion"+JSON.stringify(promocion['FechaInicioPromocionGarantia12']));
                // console.log(element['LAB'][index]['fecha']);
                // console.log(element['LAB'][index]['WehadentMesesGarantiaPromo']);


                var time1 = moment(element['LAB'][index]['Fecha']).format('YYYY-MM-DD');
                var timeinicio = moment(promocion['FechaInicioPromocionGarantia12']).format('YYYY-MM-DD');
                var timefinal = moment(promocion['FechaFinPromocionGarantia12']).format('YYYY-MM-DD');

                // console.log('está entre estas dos fechas');
                // console.log("si están entre estas dos fechas"+time1);
                // console.log("si están entre estas dos fechas"+ timeinicio);
                // console.log("is están entre estas dos fechas"+timefinal);

                if (time1 >= timeinicio && time1 <= timefinal) {

                  this.fechagarantia_LAB = moment(this.fechafactura_LAB).add(element['LAB'][index]['WehadentMesesGarantiaPromo'], 'M').format('YYYY-MM-DD');
                  //console.log('y coincide entre las dos fechas'+this.fechagarantia_LAB);
                  this.boton2 = false;
                  this.boton3 = true;
                  this.clases = "fondoverde";

                }



              } else {
                //this.fechafactura_LAB = moment("12-25-2021", "MM-DD-YYYY").fromNow();
                //this.boton2= true;
                //this.boton3 = false;

              }






            });

            // alert(this.boton2);
            // alert(this.boton3);

            let array_LAB = [{
              CIFDNI: this.misproductos_LAB['LAB'][index]['CIFDNI'],
              CodigoArticulo: this.misproductos_LAB['LAB'][index]['CodigoArticulo'],
              CodigoFamilia: this.misproductos_LAB['LAB'][index]['CodigoFamilia'],
              DescripcionArticulo: this.misproductos_LAB['LAB'][index]['DescripcionArticulo'],
              MesesGarantiaVenta: this.misproductos_LAB['LAB'][index]['MesesGarantiaVenta'],
              NumeroSerie: this.misproductos_LAB['LAB'][index]['NumeroSerie'],
              Referencia: this.misproductos_LAB['LAB'][index]['Referencia'],
              WehadentMesesGarantiaPromo: this.misproductos_LAB['LAB'][index]['WehadentMesesGarantiaPromo'],
              WehadentPrecioGarantia12Meses: this.misproductos_LAB['LAB'][index]['WehadentPrecioGarantia12Meses'],
              WehadentPrecioGarantia24Meses: this.misproductos_LAB['LAB'][index]['WehadentPrecioGarantia24Meses'],
              WehadentPrecioGarantia36Meses: this.misproductos_LAB['LAB'][index]['WehadentPrecioGarantia36Meses'],
              estado: this.misproductos_LAB['LAB'][index]['estado'],
              factura: this.misproductos_LAB['LAB'][index]['factura'],
              fecha: this.misproductos_LAB['LAB'][index]['fecha'],
              id: this.misproductos_LAB['LAB'][index]['id'],
              ceco: this.misproductos_OSS['LAB'][index]['ceco'],
              fechagarantia: this.fechafactura_LAB,
              fechagarantia2: this.fechagarantia_LAB,
              boton2: this.boton2,
              boton3: this.boton3,
              boton4: this.boton4,
              desplegable: false,
              ampliacion_gar: false,
              Opciones_gar: {},
            }];
            await this._misproductos.shopproducts(cif).subscribe(async (test: any) => {
              if (test.length) {
                for (let pos = 0; pos < test.length; pos++) {
                  let GarantiaVigente = false;
                  if (array_LAB[0]["Referencia"] == test[pos]['Referencia']) { GarantiaVigente = true };
                  let fecha_comprobaciones = (array_LAB[0].fechagarantia2) ? array_LAB[0].fechagarantia2 : array_LAB[0].fechagarantia;
                  array_LAB[0].Opciones_gar = this.calcularOpcionesGarantia(array_LAB[0].fecha, array_LAB[0].WehadentPrecioGarantia12Meses, array_LAB[0].WehadentPrecioGarantia24Meses, array_LAB[0].WehadentPrecioGarantia36Meses, GarantiaVigente, fecha_comprobaciones);
                  array_LAB[0].ampliacion_gar = this.calcularOptarGarantia(array_LAB[0].Opciones_gar);
                  if (GarantiaVigente) {
                    array_LAB[0]["TipoGar"] = test[pos]['TipoGar'];
                    array_LAB[0]["FechaCompraGarantia"] = test[pos]['FechaCompraGarantia'];
                    array_LAB[0].boton4 = true;
                    pos = test.length;
                  }
                }
              } else {
                let fecha_comprobaciones = (array_LAB[0].fechagarantia2) ? array_LAB[0].fechagarantia2 : array_LAB[0].fechagarantia;
                array_LAB[0].Opciones_gar = this.calcularOpcionesGarantia(array_LAB[0].fecha, array_LAB[0].WehadentPrecioGarantia12Meses, array_LAB[0].WehadentPrecioGarantia24Meses, array_LAB[0].WehadentPrecioGarantia36Meses, false, fecha_comprobaciones);
                array_LAB[0].ampliacion_gar = this.calcularOptarGarantia(array_LAB[0].Opciones_gar);
              }
            });

            this.newarray_LAB.push(...array_LAB);

            //console.log(this.newarray_LAB);


          }//acaba el for


        }/// acaba el elent LAB

        if (element['OSS'] != "") {

          // this.mesesgarantia_OSS = this.misproductos_OSS[0]['MesesGarantiaVenta'];
          // this.fechafactura_OSS = this.misproductos_OSS[0]['fecha'];
          // this.fechafactura_OSS = moment(this.fechafactura_OSS).add(this.mesesgarantia_OSS, 'months');



          // console.log("this.fechafactura_OSS"+this.fechafactura_OSS);
          // console.log("this.mesesgarantia_OSS"+this.mesesgarantia_OSS);
          // console.log("this.misproductos_OSS[0]['fecha']"+this.misproductos_OSS[0]['fecha']);


          for (let index = 0; index < element['OSS'].length; index++) {



            //console.log(element['OSS'][index]['fecha']);
            //console.log(element['OSS'][index]);

            this.mesesgarantia_OSS = element['OSS'][index]['MesesGarantiaVenta'];
            this.fechafactura_OSS = moment(element['OSS'][index]['fecha']).format('YYYY-MM-DD');
            this.fechafactura_OSS = moment(this.fechafactura_OSS).add(this.mesesgarantia_OSS, 'M').format('YYYY-MM-DD');

            this.boton2 = true;
            this.boton3 = false;

            // console.log(this.copiaArrayPromociones);
            // console.log( element['OSS'][index]['CodigoArticulo']);
            // console.log( element['OSS'][index]);
            // console.log(this.fechafactura_OSS);
            // console.log(this.mesesgarantia_OSS);


            this.fechagarantia_OSS = "";
            this.copiaArrayPromociones.forEach(promocion => {


              if (promocion['CodigoArticulo'] === element['OSS'][index]['CodigoArticulo']) {
                // console.log("promocion"+JSON.stringify(promocion['FechaInicioPromocionGarantia12']));
                // console.log(element['OSS'][index]['fecha']);
                // console.log(element['OSS'][index]['WehadentMesesGarantiaPromo']);


                var time1 = moment(element['OSS'][index]['Fecha']).format('YYYY-MM-DD');
                var timeinicio = moment(promocion['FechaInicioPromocionGarantia12']).format('YYYY-MM-DD');
                var timefinal = moment(promocion['FechaFinPromocionGarantia12']).format('YYYY-MM-DD');

                // console.log('está entre estas dos fechas');
                // console.log("si están entre estas dos fechas"+time1);
                // console.log("si están entre estas dos fechas"+ timeinicio);
                // console.log("is están entre estas dos fechas"+timefinal);

                if (time1 >= timeinicio && time1 <= timefinal) {

                  this.fechagarantia_OSS = moment(this.fechafactura_OSS).add(element['OSS'][index]['WehadentMesesGarantiaPromo'], 'M').format('YYYY-MM-DD');
                  //console.log('y coincide entre las dos fechas'+this.fechagarantia_OSS);
                  this.boton2 = false;
                  this.boton3 = true;
                  this.clases = "fondoverde";

                }



              } else {
                //this.fechafactura_OSS = moment("12-25-2021", "MM-DD-YYYY").fromNow();
                //this.boton2= true;
                //this.boton3 = false;

              }






            });

            // alert(this.boton2);
            // alert(this.boton3);

            let array_OSS = [{
              CIFDNI: this.misproductos_OSS['OSS'][index]['CIFDNI'],
              CodigoArticulo: this.misproductos_OSS['OSS'][index]['CodigoArticulo'],
              CodigoFamilia: this.misproductos_OSS['OSS'][index]['CodigoFamilia'],
              DescripcionArticulo: this.misproductos_OSS['OSS'][index]['DescripcionArticulo'],
              MesesGarantiaVenta: this.misproductos_OSS['OSS'][index]['MesesGarantiaVenta'],
              NumeroSerie: this.misproductos_OSS['OSS'][index]['NumeroSerie'],
              Referencia: this.misproductos_OSS['OSS'][index]['Referencia'],
              WehadentMesesGarantiaPromo: this.misproductos_OSS['OSS'][index]['WehadentMesesGarantiaPromo'],
              WehadentPrecioGarantia12Meses: this.misproductos_OSS['OSS'][index]['WehadentPrecioGarantia12Meses'],
              WehadentPrecioGarantia24Meses: this.misproductos_OSS['OSS'][index]['WehadentPrecioGarantia24Meses'],
              WehadentPrecioGarantia36Meses: this.misproductos_OSS['OSS'][index]['WehadentPrecioGarantia36Meses'],
              estado: this.misproductos_OSS['OSS'][index]['estado'],
              factura: this.misproductos_OSS['OSS'][index]['factura'],
              fecha: this.misproductos_OSS['OSS'][index]['fecha'],
              id: this.misproductos_OSS['OSS'][index]['id'],
              ceco: this.misproductos_OSS['OSS'][index]['ceco'],
              fechagarantia: this.fechafactura_OSS,
              fechagarantia2: this.fechagarantia_OSS,
              boton2: this.boton2,
              boton3: this.boton3,
              boton4: this.boton4,
              desplegable: false,
              ampliacion_gar: false,
              Opciones_gar: {},
            }];
            await this._misproductos.shopproducts(cif).subscribe(async (test: any) => {
              if (test.length) {
                for (let pos = 0; pos < test.length; pos++) {
                  let GarantiaVigente = false;
                  if (array_OSS[0]["Referencia"] == test[pos]['Referencia']) { GarantiaVigente = true };
                  let fecha_comprobaciones = (array_OSS[0].fechagarantia2) ? array_OSS[0].fechagarantia2 : array_OSS[0].fechagarantia;
                  array_OSS[0].Opciones_gar = this.calcularOpcionesGarantia(array_OSS[0].fecha, array_OSS[0].WehadentPrecioGarantia12Meses, array_OSS[0].WehadentPrecioGarantia24Meses, array_OSS[0].WehadentPrecioGarantia36Meses, GarantiaVigente, fecha_comprobaciones);
                  array_OSS[0].ampliacion_gar = this.calcularOptarGarantia(array_OSS[0].Opciones_gar);
                  if (GarantiaVigente) {
                    array_OSS[0]["TipoGar"] = test[pos]['TipoGar'];
                    array_OSS[0]["FechaCompraGarantia"] = test[pos]['FechaCompraGarantia'];
                    array_OSS[0].boton4 = true;
                    pos = test.length;
                  }
                }
              } else {
                let fecha_comprobaciones = (array_OSS[0].fechagarantia2) ? array_OSS[0].fechagarantia2 : array_OSS[0].fechagarantia;
                array_OSS[0].Opciones_gar = this.calcularOpcionesGarantia(array_OSS[0].fecha, array_OSS[0].WehadentPrecioGarantia12Meses, array_OSS[0].WehadentPrecioGarantia24Meses, array_OSS[0].WehadentPrecioGarantia36Meses, false, fecha_comprobaciones);
                array_OSS[0].ampliacion_gar = this.calcularOptarGarantia(array_OSS[0].Opciones_gar);
              }
            });

            this.newarray_OSS.push(...array_OSS);

            // console.log(this.newarray_OSS);


          }//acaba el for


        }/// acaba el elent OSS

        if (element['UES'] != "") {

          // this.mesesgarantia_UES = this.misproductos_UES[0]['MesesGarantiaVenta'];
          // this.fechafactura_UES = this.misproductos_UES[0]['fecha'];
          // this.fechafactura_UES = moment(this.fechafactura_UES).add(this.mesesgarantia_UES, 'months');



          // console.log("this.fechafactura_UES"+this.fechafactura_UES);
          // console.log("this.mesesgarantia_UES"+this.mesesgarantia_UES);
          // console.log("this.misproductos_UES[0]['fecha']"+this.misproductos_UES[0]['fecha']);


          for (let index = 0; index < element['UES'].length; index++) {



            //console.log(element['UES'][index]['fecha']);
            //console.log(element['UES'][index]);

            this.mesesgarantia_UES = element['UES'][index]['MesesGarantiaVenta'];
            this.fechafactura_UES = moment(element['UES'][index]['fecha']).format('YYYY-MM-DD');
            this.fechafactura_UES = moment(this.fechafactura_UES).add(this.mesesgarantia_UES, 'M').format('YYYY-MM-DD');

            this.boton2 = true;
            this.boton3 = false;

            // console.log(this.copiaArrayPromociones);
            // console.log( element['UES'][index]['CodigoArticulo']);
            // console.log( element['UES'][index]);
            // console.log(this.fechafactura_UES);
            // console.log(this.mesesgarantia_UES);


            this.fechagarantia_UES = "";
            this.copiaArrayPromociones.forEach(promocion => {


              if (promocion['CodigoArticulo'] === element['UES'][index]['CodigoArticulo']) {
                // console.log("promocion"+JSON.stringify(promocion['FechaInicioPromocionGarantia12']));
                // console.log(element['UES'][index]['fecha']);
                // console.log(element['UES'][index]['WehadentMesesGarantiaPromo']);


                var time1 = moment(element['UES'][index]['Fecha']).format('YYYY-MM-DD');
                var timeinicio = moment(promocion['FechaInicioPromocionGarantia12']).format('YYYY-MM-DD');
                var timefinal = moment(promocion['FechaFinPromocionGarantia12']).format('YYYY-MM-DD');

                // console.log('está entre estas dos fechas');
                // console.log("si están entre estas dos fechas"+time1);
                // console.log("si están entre estas dos fechas"+ timeinicio);
                // console.log("is están entre estas dos fechas"+timefinal);

                if (time1 >= timeinicio && time1 <= timefinal) {

                  this.fechagarantia_UES = moment(this.fechafactura_UES).add(element['UES'][index]['WehadentMesesGarantiaPromo'], 'M').format('YYYY-MM-DD');
                  //console.log('y coincide entre las dos fechas'+this.fechagarantia_UES);
                  this.boton2 = false;
                  this.boton3 = true;
                  this.clases = "fondoverde";

                }



              } else {
                //this.fechafactura_UES = moment("12-25-2021", "MM-DD-YYYY").fromNow();
                //this.boton2= true;
                //this.boton3 = false;

              }






            });

            // alert(this.boton2);
            // alert(this.boton3);

            let array_UES = [{
              CIFDNI: this.misproductos_UES['UES'][index]['CIFDNI'],
              CodigoArticulo: this.misproductos_UES['UES'][index]['CodigoArticulo'],
              CodigoFamilia: this.misproductos_UES['UES'][index]['CodigoFamilia'],
              DescripcionArticulo: this.misproductos_UES['UES'][index]['DescripcionArticulo'],
              MesesGarantiaVenta: this.misproductos_UES['UES'][index]['MesesGarantiaVenta'],
              NumeroSerie: this.misproductos_UES['UES'][index]['NumeroSerie'],
              Referencia: this.misproductos_UES['UES'][index]['Referencia'],
              WehadentMesesGarantiaPromo: this.misproductos_UES['UES'][index]['WehadentMesesGarantiaPromo'],
              WehadentPrecioGarantia12Meses: this.misproductos_UES['UES'][index]['WehadentPrecioGarantia12Meses'],
              WehadentPrecioGarantia24Meses: this.misproductos_UES['UES'][index]['WehadentPrecioGarantia24Meses'],
              WehadentPrecioGarantia36Meses: this.misproductos_UES['UES'][index]['WehadentPrecioGarantia36Meses'],
              estado: this.misproductos_UES['UES'][index]['estado'],
              factura: this.misproductos_UES['UES'][index]['factura'],
              fecha: this.misproductos_UES['UES'][index]['fecha'],
              id: this.misproductos_UES['UES'][index]['id'],
              ceco: this.misproductos_OSS['UES'][index]['ceco'],
              fechagarantia: this.fechafactura_UES,
              fechagarantia2: this.fechagarantia_UES,
              boton2: this.boton2,
              boton3: this.boton3,
              boton4: this.boton4,
              desplegable: false,
              ampliacion_gar: false,
              Opciones_gar: {},
            }];
            await this._misproductos.shopproducts(cif).subscribe(async (test: any) => {
              if (test.length) {
                for (let pos = 0; pos < test.length; pos++) {
                  let GarantiaVigente = false;
                  if (array_UES[0]["Referencia"] == test[pos]['Referencia']) { GarantiaVigente = true };
                  let fecha_comprobaciones = (array_UES[0].fechagarantia2) ? array_UES[0].fechagarantia2 : array_UES[0].fechagarantia;
                  array_UES[0].Opciones_gar = this.calcularOpcionesGarantia(array_UES[0].fecha, array_UES[0].WehadentPrecioGarantia12Meses, array_UES[0].WehadentPrecioGarantia24Meses, array_UES[0].WehadentPrecioGarantia36Meses, GarantiaVigente, fecha_comprobaciones);
                  array_UES[0].ampliacion_gar = this.calcularOptarGarantia(array_UES[0].Opciones_gar);
                  if (GarantiaVigente) {
                    array_UES[0]["TipoGar"] = test[pos]['TipoGar'];
                    array_UES[0]["FechaCompraGarantia"] = test[pos]['FechaCompraGarantia'];
                    array_UES[0].boton4 = true;
                    pos = test.length;
                  }
                }
              } else {
                let fecha_comprobaciones = (array_UES[0].fechagarantia2) ? array_UES[0].fechagarantia2 : array_UES[0].fechagarantia;
                array_UES[0].Opciones_gar = this.calcularOpcionesGarantia(array_UES[0].fecha, array_UES[0].WehadentPrecioGarantia12Meses, array_UES[0].WehadentPrecioGarantia24Meses, array_UES[0].WehadentPrecioGarantia36Meses, false, fecha_comprobaciones);
                array_UES[0].ampliacion_gar = this.calcularOptarGarantia(array_UES[0].Opciones_gar);
              }
            });


            this.newarray_UES.push(...array_UES);

            //console.log(this.newarray_UES);


          }//acaba el for


        }/// acaba el elent UES


      });










    });

  }



  solicitarasistencia() {
    this.navCtrl.navigateForward('/asistenciatecnica');
  }


  micuenta() {
    this.navCtrl.navigateForward('/micuenta');
  }

  miPanel() {
    this.navCtrl.navigateForward('/dashboard');
  }


  shop() {
    this.navCtrl.navigateForward('/shop');
  }


  async nuevoproducto() {
    const modal = await this.modalController.create({
      component: NuevoproductoPage,
      componentProps: {
        'model_title': "Crear Nuevo Producto"
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        // console.log('Modal Data : ' + modelData.data);
      }
    });

    return await modal.present();
  }


  linkfactura(factura) {

    //  console.log(factura);

    var url = "https://www.whiberica.es/APIWH/" + factura;

    window.open(url);

  }



  async eliminar(dato) {

    //console.log(dato);

    const alert = await this.alertCotroller.create({
      cssClass: 'my-custom-class',
      header: 'Está seguro de eliminar este procucto ?',
      message: dato['DescripcionArticulo'],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel');
          }
        }, {
          text: 'Eliminar',
          handler: () => {
            // console.log('Confirm Okay'+ dato);

            this._misproductos.borrararticulo(dato).subscribe(async (ans: []) => {


              this.respuesta = ans;

              // console.log(this.respuesta);

              if (this.respuesta == 'error') {


                const loading = await this.loadingController.create({
                  spinner: null,
                  duration: 1000,
                  message: this.respuesta['texto'],
                  translucent: true,
                  // cssClass: 'custom-class custom-loading',
                  backdropDismiss: true
                });
                await loading.present();



              } else {

                const loading = await this.loadingController.create({
                  spinner: null,
                  duration: 1000,
                  message: this.respuesta['texto'],
                  translucent: true,
                  // cssClass: 'custom-class custom-loading',
                  backdropDismiss: true
                });
                await loading.present();

                await this.iniciarlistadoproductos();

              }



            });


          }
        }
      ]
    });



    await alert.present();


  }



  async editar(datos) {

    const modal = await this.modalController.create({
      component: EditarproductoPage,
      componentProps: {
        'model_title': "Crear Nuevo Producto",
        'datos': datos
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        this.iniciarlistadoproductos();
        // console.log('Modal Data : ' + modelData.data);
      }
    });

    return await modal.present();

  }



}
