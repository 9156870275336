import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], term: string): any[] {
    if (!items) return [];
    if (!term) return items;

    term = term.toLowerCase();

    return items.filter(item => {
      return Object.values(item).some(value => {
        // Asegúrate de que el valor no es null ni undefined antes de llamar a toString()
        return value != null && value.toString().toLowerCase().includes(term);
      });
    });
  }

}
