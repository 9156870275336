import { Injectable, Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpParams } from '@angular/common/http';
import { map , catchError, retry, tap } from 'rxjs/operators';
import { Observable , of, throwError } from 'rxjs';
import { RutasService } from './rutas.service';


@Injectable({
  providedIn: 'root'
})
export class CrudTablasService {

  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(  public _apiruta: RutasService,
                private http: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Content-Type': 'x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization,Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Credentials': 'true',
      method: 'POST'
    })
  };

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  getTabla( tabla ) {

    // console.log(datos);
    var headers = new Headers();
     headers.append("Accept", 'application/json');
     headers.append('Content-Type', 'application/json' );

    const payload = new HttpParams()
    .set('tabla', tabla)

    return this.http.post(this._apiruta.BASE_RUTA+"CrudTablas", payload )
    .pipe(map(
      dat => {
        //console.log('res ' + JSON.stringify(dat));
        return dat;
      },
      err =>
        console.log(err)
    ));

  }

  deleteInDatabase( data, old_dir? ){

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );


    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('tabla', data.tabla);
    if (old_dir) { formData.append('old_dir', old_dir); }


    return this.http.post(this._apiruta.BASE_RUTA+"CrudTablas/delete", formData )
    .pipe(map(
      dat => {
        //console.log('res ' + JSON.stringify(dat));
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  editInDatabase( data ) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const payload = new HttpParams()
    .set('tabla', data.tabla )
    .set('datos', data.datos )

    return this.http.post(this._apiruta.BASE_RUTA+"CrudTablas/edit", payload )
    .pipe(map(
      dat => {
        //console.log('res ' + JSON.stringify(dat));
        return dat;
      },
      err =>
        console.log(err)
    ));
  }


  getDatosPrestamo( token ) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const payload = new HttpParams()
    .set('token', token )
    .set('tabla', 'prestamos')

    return this.http.post(this._apiruta.BASE_RUTA+"TablasComerciales/extras", payload )
    .pipe(map(
      dat => {
        //console.log('res ' + JSON.stringify(dat));
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  getDatosFormacion( token ) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const payload = new HttpParams()
    .set('token', token )
    .set('tabla', 'formacion')


    return this.http.post(this._apiruta.BASE_RUTA+"TablasComerciales/extras", payload )
    .pipe(map(
      dat => {
        //console.log('res ' + JSON.stringify(dat));
        return dat;
      },
      err =>
        console.log(err)
    ));
  }



  getDatosPedidos( token ) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const payload = new HttpParams()
    .set('token', token )
    .set('tabla', 'pedidos')


    return this.http.post(this._apiruta.BASE_RUTA+"TablasComerciales/extras", payload )
    .pipe(map(
      dat => {
        //console.log('res ' + JSON.stringify(dat));
        return dat;
      },
      err =>
        console.log(err)
    ));
  }



  getDatosReparaciones( token ) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const payload = new HttpParams()
    .set('token', token )
    .set('tabla', 'reparaciones')


    return this.http.post(this._apiruta.BASE_RUTA+"TablasComerciales/extras", payload )
    .pipe(map(
      dat => {
        //console.log('res ' + JSON.stringify(dat));
        return dat;
      },
      err =>
        console.log(err)
    ));
  }




  actualizarPrestamo(datos, token, id_prestamo) {
    var headers = new Headers();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json' );
    // articulos = JSON.stringify(articulos);
    datos = JSON.stringify(datos);

  // const formData = new FormData();
  // formData.append('datos', datos )
  // formData.append('tabla', "prestamos" )
  const payload = new HttpParams()
    .set('datos', datos)
    .set('id_prestamo', id_prestamo)
    .set('accion', 'confirmar')
    .set('token', token)
  return this.http.post(this._apiruta.BASE_RUTA+"TablasComerciales/correo", payload)
    .pipe(map(
      dat => {
        console.log('ActualizarFormacion',dat);
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  actualizarFormacion(datos, token) {
    var headers = new Headers();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json' );
    // articulos = JSON.stringify(articulos);
    datos = JSON.stringify(datos);

  // const formData = new FormData();
  // formData.append('datos', datos )
  // formData.append('tabla', "prestamos" )
  const payload = new HttpParams()
    .set('datos', datos)
    .set('tabla', 'formacion')
    .set('correo', "true")
    .set('token', token)
  return this.http.post(this._apiruta.BASE_RUTA+"TablasComerciales/update", payload)
    .pipe(map(
      dat => {
        console.log('ActualizarFormacion',dat);
        return dat;
      },
      err =>
        console.log(err)
    ));
  }


  addToDatabase( data, archivo1?, archivo2? ) {
    // console.log(datos);
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const formData = new FormData();
    if (archivo1) {
      if (archivo2) {
        // 2 PARAMETROS -> SLIDERS
        formData.append('izq', archivo1);
        formData.append('der', archivo2);
      } else {
        // 1 PARAMETRO -> FACTURA DE ARTICULOS GARANTIA
        formData.append('Factura', archivo1);
      }
    }
    formData.append('tabla', data.tabla )
    formData.append('datos', data.datos )

    // const payload = new HttpParams()
    // .set('tabla', data.tabla )
    // .set('datos', data.datos )
    console.log("prueba", archivo1);
    return this.http.post(this._apiruta.BASE_RUTA+"CrudTablas/add", formData )
    .pipe(map(
      dat => {
        //console.log('res ' + JSON.stringify(dat));
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  getSliders() {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    return this.http.post(this._apiruta.BASE_RUTA+"CrudTablas/sliders", headers )
    .pipe(map(
      dat => {
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  cambiarEstadoSlider(id, activa) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const formData = new FormData();
    formData.append('id', id )
    formData.append('activa', activa )

    return this.http.post(this._apiruta.BASE_RUTA+"CrudTablas/cambiarEstadoSlider", formData )
    .pipe(map(
      dat => {
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  cambiarFacturaArtGar(CIFDNI, Referencia, id, factura, old_dir) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const formData = new FormData();
    formData.append('CIFDNI', CIFDNI )
    formData.append('Referencia', Referencia )
    formData.append('id', id )
    formData.append('Factura', factura )
    formData.append('old_dir', old_dir )



    return this.http.post(this._apiruta.BASE_RUTA+"CrudTablas/subirpdf", formData )
    .pipe(map(
      dat => {
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  cambiarImagenSlider(id, imagen, ruta) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    const formData = new FormData();
    formData.append('id', id )
    formData.append('imagen', imagen )
    formData.append('ruta', ruta )
    return this.http.post(this._apiruta.BASE_RUTA+"CrudTablas/cambiarImagenSlider", formData )
    .pipe(map(
      dat => {
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

}
