import { Component, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { UsuarioavanzadoService } from 'src/app/servicios/usuarioavanzado.service';

import * as XLSX from 'xlsx';


@Component({
  selector: 'app-sanitas',
  templateUrl: './sanitas.page.html',
  styleUrls: ['./sanitas.page.scss'],
})
export class SANITASPage implements OnInit {

  lastClicked: number = 1;
  cardClicked: boolean = false;

  termVacausVLC: string = '';
  termVacausProduccion: string = '';
  termVacausReparacion: string = '';
  termVacausTransito: string = '';

  respuesta: any;

  title = "Notificaciones";
  ArrayDataReservaVLC: any[] = [];
  ArrayDataEnReparacion: any[] = [];
  ArrayDataEnTransito: any[] = [];
  ArrayDataEnProduccion: any[] = [];

  listEnProduccion: any[] = [];
  listEnReparacion: any[] = [];
  listPartesSage: any[] = [];
  listEnTransito: any[] = [];

  filteredListEnProduccion: any[] = [];
  filteredListEnReparacion: any[] = [];
  filteredListEnTransito: any[] = [];
  filteredArrayDataReservaVLC: any[] = [];

  currentPageVLC = 1;
  currentPageProduccion = 1;
  currentPageReparacion = 1;
  // currentPageTransito = 1;

  itemsPerPageVLC = 12;
  itemsPerPageProduccion = 15;
  itemsPerPageReparacion = 15;
  // itemsPerPageTransito = 15;


  currentPageTransito: number = 1;
  itemsPerPageTransito: number = 10;
  pagesTransito: number[] = [];

  // Variables para el manejo de bloques de páginas
  pageBlockProduccion: number = 0;
  visiblePagesProduccion: number[] = [];
  maxPageBlockProduccion: number = 0;

  pageBlockVLC: number = 0;
  visiblePagesVLC: number[] = [];
  maxPageBlockVLC: number = 0;

  pageBlockReparacion: number = 0;
  visiblePagesReparacion: number[] = [];
  maxPageBlockReparacion: number = 0;

  pageBlockTransito: number = 0;
  visiblePagesTransito: number[] = [];
  maxPageBlockTransito: number = 0;

  // currentReparacion: 1;


  pagesVLC: number[] = [];
  pagesProduccion: number[] = [];
  pagesReparacion: number[] = [];
  // pagesTransito: number[] = [];

  sortColumn: string = '';
  sortAscending: boolean = true;




  sumatorioArrayDAtaReservaVLC: number = 0;
  sumatorioArraylistEnProduccion: number = 0;
  sumatorioArraylistEnReparacion: number = 0;
  sumatorioArraylistTransito: number = 0;
  sumatorioTotal: number = 0;

  listEnReparacionFiltrada: any[] = [];
  resultadoProduccionReparacion: any[];
  resultadoProduccionentransito: any[];


  listarTransito: any[];
  loading: HTMLIonLoadingElement;
  resultadoEnTransito: any;
  datosPrepExcel: any;
  totalpartesSage: any[];
  resultadoTotalIncidencias: any[];
  listParteIncidencias: any;



  constructor(private usuarioavanzadoService: UsuarioavanzadoService, public loadingCtrl: LoadingController,) { }

  async ngOnInit() {
    // console.log("SANITAS Page");

    setTimeout(async () => {
      await this.listarEnProduccion();

    }, 115);

    setTimeout(async () => {

      await this.reservaVlc();

    }, 117);

  }

  async toggleCardShadow(cardIndex: number) {
    // console.log("toggleCardShadow: " + cardIndex);
    this.lastClicked = cardIndex;

    this.termVacausVLC = '';
    this.termVacausProduccion = '';
    this.termVacausReparacion = '';
    this.termVacausTransito = '';


    if (this.lastClicked == 5) {
      this.ngOnInit();
      this.lastClicked = 1;
    }

  }


  updatePagination(set: string) {
    if (set === 'Produccion') {
      this.pagesProduccion = Array(Math.ceil(this.filteredListEnProduccion.length / this.itemsPerPageProduccion)).fill(0).map((x, i) => i + 1);
      if (this.currentPageProduccion > this.pagesProduccion.length) {
        this.currentPageProduccion = this.pagesProduccion.length;
      }
      this.maxPageBlockProduccion = Math.floor((this.pagesProduccion.length - 1) / 10);
      this.updateVisiblePages('Produccion');
    } else if (set === 'VLC') {
      this.pagesVLC = Array(Math.ceil(this.filteredArrayDataReservaVLC.length / this.itemsPerPageVLC)).fill(0).map((x, i) => i + 1);
      if (this.currentPageVLC > this.pagesVLC.length) {
        this.currentPageVLC = this.pagesVLC.length;
      }
      this.maxPageBlockVLC = Math.floor((this.pagesVLC.length - 1) / 10);
      this.updateVisiblePages('VLC');
    } else if (set === 'Reparacion') {
      this.pagesReparacion = Array(Math.ceil(this.filteredListEnReparacion.length / this.itemsPerPageReparacion)).fill(0).map((x, i) => i + 1);
      if (this.currentPageReparacion > this.pagesReparacion.length) {
        this.currentPageReparacion = this.pagesReparacion.length;
      }
      this.maxPageBlockReparacion = Math.floor((this.pagesReparacion.length - 1) / 10);
      this.updateVisiblePages('Reparacion');
    } else if (set === 'Transito') {
      this.pagesTransito = Array(Math.ceil(this.filteredListEnTransito.length / this.itemsPerPageTransito)).fill(0).map((x, i) => i + 1);
      if (this.currentPageTransito > this.pagesTransito.length) {
        this.currentPageTransito = this.pagesTransito.length;
      }
      this.maxPageBlockTransito = Math.floor((this.pagesTransito.length - 1) / 10);
      this.updateVisiblePages('Transito');
    }
  }

  updateVisiblePages(set: string) {
    if (set === 'Produccion') {
      this.visiblePagesProduccion = this.pagesProduccion.slice(this.pageBlockProduccion * 10, (this.pageBlockProduccion + 1) * 10);
    } else if (set === 'VLC') {
      this.visiblePagesVLC = this.pagesVLC.slice(this.pageBlockVLC * 10, (this.pageBlockVLC + 1) * 10);
    } else if (set === 'Reparacion') {
      this.visiblePagesReparacion = this.pagesReparacion.slice(this.pageBlockReparacion * 10, (this.pageBlockReparacion + 1) * 10);
    } else if (set === 'Transito') {
      this.visiblePagesTransito = this.pagesTransito.slice(this.pageBlockTransito * 10, (this.pageBlockTransito + 1) * 10);
    }
  }

  prevPageBlock(set: string) {
    if (set === 'Produccion' && this.pageBlockProduccion > 0) {
      this.pageBlockProduccion--;
      this.updateVisiblePages('Produccion');
    } else if (set === 'VLC' && this.pageBlockVLC > 0) {
      this.pageBlockVLC--;
      this.updateVisiblePages('VLC');
    } else if (set === 'Reparacion' && this.pageBlockReparacion > 0) {
      this.pageBlockReparacion--;
      this.updateVisiblePages('Reparacion');
    } else if (set === 'Transito' && this.pageBlockTransito > 0) {
      this.pageBlockTransito--;
      this.updateVisiblePages('Transito');
    }
  }

  nextPageBlock(set: string) {
    if (set === 'Produccion' && this.pageBlockProduccion < this.maxPageBlockProduccion) {
      this.pageBlockProduccion++;
      this.updateVisiblePages('Produccion');
    } else if (set === 'VLC' && this.pageBlockVLC < this.maxPageBlockVLC) {
      this.pageBlockVLC++;
      this.updateVisiblePages('VLC');
    } else if (set === 'Reparacion' && this.pageBlockReparacion < this.maxPageBlockReparacion) {
      this.pageBlockReparacion++;
      this.updateVisiblePages('Reparacion');
    } else if (set === 'Transito' && this.pageBlockTransito < this.maxPageBlockTransito) {
      this.pageBlockTransito++;
      this.updateVisiblePages('Transito');
    }
  }

  prevPage(set: string) {
    // console.log('prevPage', set);

    if (set === 'Produccion' && this.currentPageProduccion > 1) {
      this.currentPageProduccion--;
      if (this.currentPageProduccion <= this.pageBlockProduccion * 10) {
        this.prevPageBlock('Produccion');
      }
    } else if (set === 'VLC' && this.currentPageVLC > 1) {
      this.currentPageVLC--;
      if (this.currentPageVLC <= this.pageBlockVLC * 10) {
        this.prevPageBlock('VLC');
      }
    } else if (set === 'Reparacion' && this.currentPageReparacion > 1) {
      this.currentPageReparacion--;
      if (this.currentPageReparacion <= this.pageBlockReparacion * 10) {
        this.prevPageBlock('Reparacion');
      }
    } else if (set === 'Transito' && this.currentPageTransito > 1) {
      this.currentPageTransito--;
      if (this.currentPageTransito <= this.pageBlockTransito * 10) {
        this.prevPageBlock('Transito');
      }
    }
  }

  nextPage(set: string) {
    // console.log('nextPage', set);

    if (set === 'Produccion' && this.currentPageProduccion < this.pagesProduccion.length) {
      this.currentPageProduccion++;
      if (this.currentPageProduccion > (this.pageBlockProduccion + 1) * 10) {
        this.nextPageBlock('Produccion');
      }
    } else if (set === 'VLC' && this.currentPageVLC < this.pagesVLC.length) {
      this.currentPageVLC++;
      if (this.currentPageVLC > (this.pageBlockVLC + 1) * 10) {
        this.nextPageBlock('VLC');
      }
    } else if (set === 'Reparacion' && this.currentPageReparacion < this.pagesReparacion.length) {
      this.currentPageReparacion++;
      if (this.currentPageReparacion > (this.pageBlockReparacion + 1) * 10) {
        this.nextPageBlock('Reparacion');
      }
    } else if (set === 'Transito' && this.currentPageTransito < this.pagesTransito.length) {
      this.currentPageTransito++;
      if (this.currentPageTransito > (this.pageBlockTransito + 1) * 10) {
        this.nextPageBlock('Transito');
      }
    }
  }

  goToPage(set: string, page: number) {
    // console.log('goToPage', set, page);

    if (set === 'Produccion') {
      this.currentPageProduccion = page;
    } else if (set === 'VLC') {
      this.currentPageVLC = page;
    } else if (set === 'Reparacion') {
      this.currentPageReparacion = page;
    } else if (set === 'Transito') {
      this.currentPageTransito = page;
    }
  }



  // prevPage(set: string) {
  //   console.log('prevPage', set);

  //   if (set === 'VLC' && this.currentPageVLC > 1) {
  //     this.currentPageVLC--;
  //   } else if (set === 'Produccion' && this.currentPageProduccion > 1) {
  //     this.currentPageProduccion--;
  //   } else if (set === 'Reparacion' && this.currentPageReparacion > 1) {
  //     this.currentPageReparacion--;
  //   } else if (set === 'Transito' && this.currentPageTransito > 1) {
  //     this.currentPageTransito--;
  //   }
  // }

  // nextPage(set: string) {
  //   console.log('nextPage', set);

  //   if (set === 'VLC' && this.currentPageVLC < this.pagesVLC.length) {
  //     this.currentPageVLC++;
  //   } else if (set === 'Produccion' && this.currentPageProduccion < this.pagesProduccion.length) {
  //     this.currentPageProduccion++;
  //   } else if (set === 'Reparacion' && this.currentPageReparacion < this.pagesReparacion.length) {
  //     this.currentPageReparacion++;
  //   } else if (set === 'Transito' && this.currentPageTransito < this.pagesTransito.length) {
  //     this.currentPageTransito++;
  //   }

  // }

  // goToPage(set: string, page: number) {
  //   console.log('goToPage', set, page);

  //   if (set === 'VLC') {
  //     this.currentPageVLC = page;
  //   } else if (set === 'Produccion') {
  //     this.currentPageProduccion = page;
  //   } else if (set === 'Reparacion') {
  //     this.currentPageReparacion = page;
  //   } else if (set === 'Transito') {
  //     this.currentPageTransito = page;
  //   }

  // }

  // updatePagination(set: string) {
  //   if (set === 'VLC') {
  //     this.pagesVLC = Array(Math.ceil(this.filteredArrayDataReservaVLC.length / this.itemsPerPageVLC)).fill(0).map((x, i) => i + 1);
  //     if (this.currentPageVLC > this.pagesVLC.length) {
  //       this.currentPageVLC = this.pagesVLC.length;
  //     }
  //   } else if (set === 'Produccion') {
  //     this.pagesProduccion = Array(Math.ceil(this.filteredListEnProduccion.length / this.itemsPerPageProduccion)).fill(0).map((x, i) => i + 1);
  //     if (this.currentPageProduccion > this.pagesProduccion.length) {
  //       this.currentPageProduccion = this.pagesProduccion.length;
  //     }
  //   } else if (set === 'Reparacion') {
  //     this.pagesReparacion = Array(Math.ceil(this.filteredListEnReparacion.length / this.itemsPerPageReparacion)).fill(0).map((x, i) => i + 1);
  //     if (this.currentPageReparacion > this.pagesReparacion.length) {
  //       this.currentPageReparacion = this.pagesReparacion.length;
  //     }
  //   } else if (set === 'Transito') {
  //     this.pagesTransito = Array(Math.ceil(this.filteredListEnTransito.length / this.itemsPerPageTransito)).fill(0).map((x, i) => i + 1);
  //     if (this.currentPageTransito > this.pagesTransito.length) {
  //       this.currentPageTransito = this.pagesTransito.length;
  //     }
  //   }
  // }


  filterAndPaginateData(set: string) {
    if (set === 'VLC') {
      this.filteredArrayDataReservaVLC = this.ArrayDataReservaVLC.filter(item =>
        Object.values(item).some(val => val !== null && val.toString().toLowerCase().includes(this.termVacausVLC.toLowerCase()))
      );
      this.currentPageVLC = 1; // Reset page to the first page
      this.updatePagination('VLC');
    } else if (set === 'Produccion') {
      this.filteredListEnProduccion = this.ArrayDataEnProduccion.filter(item =>
        Object.values(item).some(val => val !== null && val.toString().toLowerCase().includes(this.termVacausProduccion.toLowerCase()))
      );
      this.currentPageProduccion = 1; // Reset page to the first page
      this.updatePagination('Produccion');
    } else if (set === 'Reparacion') {
      this.filteredListEnReparacion = this.ArrayDataEnReparacion.filter(item =>
        Object.values(item).some(val => val !== null && val.toString().toLowerCase().includes(this.termVacausReparacion.toLowerCase()))
      );
      this.currentPageReparacion = 1; // Reset page to the first page
      this.updatePagination('Reparacion');
    } else if (set === 'Transito') {
      this.filteredListEnTransito = this.ArrayDataEnTransito.filter(item =>
        Object.values(item).some(val => val !== null && val.toString().toLowerCase().includes(this.termVacausTransito.toLowerCase()))
      );
      this.currentPageTransito = 1; // Reset page to the first page
      this.updatePagination('Transito');
    }
  }




  sortData(column: string, asNumber: boolean = false, set: string) {
    if (this.sortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortColumn = column;
      this.sortAscending = true;
    }

    let dataToSort;

    if (set === 'VLC') {
      dataToSort = this.filteredArrayDataReservaVLC;
    } else if (set === 'Produccion') {
      dataToSort = this.filteredListEnProduccion;
    } else if (set === 'Reparacion') {
      dataToSort = this.filteredListEnReparacion;
    } else if (set === 'Transito') {
      dataToSort = this.filteredListEnTransito;
    }

    // console.log('dataToSort', dataToSort);

    dataToSort.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      if (asNumber) {
        valueA = parseFloat(valueA);
        valueB = parseFloat(valueB);
      } else {
        if (typeof valueA === 'string') {
          valueA = valueA.toLowerCase();
        }
        if (typeof valueB === 'string') {
          valueB = valueB.toLowerCase();
        }
      }

      if (valueA < valueB) {
        return this.sortAscending ? -1 : 1;
      } else if (valueA > valueB) {
        return this.sortAscending ? 1 : -1;
      } else {
        return 0;
      }
    });
  }





  async reservaVlc() {


    //this.cargandodatos('Cargando Reserva Stock espera unos segundos...');

    this.usuarioavanzadoService.listarReservaVlc().subscribe((ans: any[]) => {
      this.ArrayDataReservaVLC = ans;
      this.filteredArrayDataReservaVLC = [...this.ArrayDataReservaVLC];
      // console.log(this.filteredArrayDataReservaVLC);

      this.sumatorioArrayDAtaReservaVLC = this.ArrayDataReservaVLC.reduce((sum, item) => sum + Math.floor(item.UnidadSaldo), 0);
      this.updatePagination('VLC');

      // if (this.loading) {
      //   console.log('Dismiss loading');
      //   this.loading.dismiss();
      // } else {
      //   console.log('Loading es undefined');
      // }

    }, (error) => {
      // Manejo del error si es necesario
      console.error(error);
      this.loading.dismiss();
    });



  }



  async listarEnProduccion() {

    // cssancho@sanitas.es

    await this.cargandodatos('Cargando En Producción espera unos segundos...');

    try {


      ////////////////////////////////////////
      //PRIMERO SACO TODOS LOS ARTICULOS QUE TENGO DE SANITAS EN MYSQL PARA PRODUCCION

      const ans = await this.usuarioavanzadoService.listarEnProduccion().toPromise();

      this.listEnProduccion = ans['datos'];
      this.filteredListEnProduccion = [...this.listEnProduccion];

      // ESTO DA TODOS LOS DATOS QUE TENEMOS EN PRODUCCION DE MATERIAL EN MYSQL

      // console.log(this.filteredListEnProduccion); //buena la variable FINAL
      const jsonFilteredListEnIncidencias = JSON.stringify(this.filteredListEnProduccion);


      ////////////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////
      //AHORA LLAMO A LA FUNCION QUE ME TRAE LOS PARTES DE SANITAS EN SAGE 

      const ansreparacion = await this.usuarioavanzadoService.listarEnPartesSage().toPromise();

      this.listPartesSage = ansreparacion as any[];

      this.totalpartesSage = this.listPartesSage;

      const filteredList = this.listPartesSage.filter(item => item.StatusParteLc != '2');


      this.filteredListEnReparacion = [...filteredList]; //buena la variable FINAL
      const jsonFilteredListEnReparacion = JSON.stringify(this.filteredListEnReparacion);

      // console.log('this.listaPARTES DE REPARACION EN SAGE se obtiene SQL' + jsonFilteredListEnReparacion);


      // cssancho@sanitas.es
      ////////////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////////
      //AHORA LLAMO A LA FUNCION QUE ME TRAE LOS LAS INCIDENCIAS EN MYSQL
      const ansincidencias = await this.usuarioavanzadoService.listarEnIncidencias(jsonFilteredListEnIncidencias).toPromise();

      // console.log('ansincidencias en base datos local mysql ', ansincidencias['datos']);

      this.resultadoTotalIncidencias = [];
      // this.resultadoEnTransito = [];
      this.listParteIncidencias = await ansincidencias['datos'];

      this.resultadoTotalIncidencias = await ansincidencias['datos'];

      this.resultadoProduccionReparacion = this.listPartesSage;


      // Arrays resultantes
      let coinciden = [];


      // SACACOMOS LOS QUE ESTÁN EN REPARACIONES sobre listParteIncidencias para encontrar coincidencias con filteredListEnReparacion para sacar las coincidencias REPARACION
      this.listParteIncidencias.forEach(parte => {
        const { NumeroSerie, Referencia, FechaSolicitud } = parte;

        const found = this.filteredListEnReparacion.some(reparacion => {
          // console.log("numero de serie", NumeroSerie);
          // console.log("numero de ceco", ceco);
          // console.log("numero de CodigoArticulo", Referencia);
          // console.log("numero de fechasolicitud", reparacion.fecha);
          // console.log("numero de reparacion.NumeroParteLc", reparacion.NumeroParteLc);
          // console.log("fechasolicitud", FechaSolicitud);

          return reparacion.NumeroSerieLc === NumeroSerie &&
            reparacion.CodigoArticulo === Referencia &&
            this.compareDates(FechaSolicitud, reparacion.fecha);
        });

        if (found) {
          coinciden.push(parte);
        }


      });


      // cssancho@sanitas.es
      //PARA SABER LOS QUE ESTÁN EN TRANSITO, COGER ARRAY TOTAL DE PARTES SAGE Y QUITAR LOS QUE ESTÁN EN REPARACION

      let noCoinciden = [];


      this.resultadoTotalIncidencias.forEach(parte => {
        const { NumeroSerie, CodigoArticulo } = parte;
        // console.log("Parte:", parte);

        const nofound = this.totalpartesSage.some(reparacion => {
          // console.log("numero de serie", NumeroSerie);
          // console.log("numero de CodigoArticulo", CodigoArticulo);
          // console.log("----------------");
          // console.log("numero de reparacion.NumeroSerie", reparacion.NumeroSerieLc);
          // console.log("numero de reparacion.Referencia", reparacion.CodigoArticulo);

          return reparacion.NumeroSerieLc === NumeroSerie &&
            reparacion.CodigoArticulo === CodigoArticulo
        });

        if (!nofound) {
          // console.log("No Coinciden:", parte);
          noCoinciden.push(parte);

        }


      });

      // console.log("this.totalpartesSage", noCoinciden);




      // Eliminamos de filteredListEnProduccion los que coinciden en coinciden array


      this.filteredListEnReparacion = coinciden;
      this.ArrayDataEnReparacion = this.filteredListEnReparacion;

      this.filteredListEnTransito = noCoinciden;
      this.ArrayDataEnTransito = this.filteredListEnTransito;




      // Array para almacenar las coincidencias
      let coincidencias = [];

      // Crear un nuevo array sin las coincidencias
      let newFilteredListEnProduccion = this.filteredListEnProduccion.filter(produccion => {

        // console.log('Producción:', produccion);
        // console.log('Producción:', this.filteredListEnReparacion);
        // console.log('Producción:', this.filteredListEnTransito);

        // console.log('produccion.NumeroSerie:', produccion.NumeroSerie);
        // console.log('produccion.CodigoArticulo:', produccion.CodigoArticulo);


        // Verificar si hay coincidencia en filteredListEnReparacion
        let isEnReparacion = this.filteredListEnReparacion.some(reparacion =>
          produccion.NumeroSerie === reparacion.NumeroSerie && produccion.CodigoArticulo === reparacion.Referencia
        );

        // Verificar si hay coincidencia en filteredListEnTransito
        let isEnTransito = this.filteredListEnTransito.some(transito =>
          produccion.NumeroSerie === transito.NumeroSerieLc && produccion.CodigoArticulo === transito.CodigoArticulo
        );

        // Si hay coincidencia en cualquiera de los arrays, agregar a coincidencias
        if (isEnReparacion || isEnTransito) {
          coincidencias.push(produccion);
          return false; // Excluir del nuevo array
        }

        return true; // Incluir en el nuevo array
      });

      this.filteredListEnProduccion = newFilteredListEnProduccion;
      this.ArrayDataEnProduccion = newFilteredListEnProduccion;

      // console.log('Nuevas en Producción:', newFilteredListEnProduccion);
      // console.log('Coincidencias:', coincidencias);

      // console.log("Coinciden:", coinciden);
      // console.log("No Coinciden:", noCoinciden);
      // console.log("Nueva Lista Producción:", this.filteredListEnProduccion);


      ////////////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////
      // cssancho@sanitas.es



    } catch (error) {
      // Manejo del error si es necesario
      console.error(error);
    } finally {
      this.loading.dismiss();
    }



    setTimeout(() => {



      // Sumar longitudes de los arrays
      const totalCoinciden = this.filteredListEnReparacion.length;
      const totalNoCoinciden = this.filteredListEnTransito.length;
      const totalNuevaListaProduccion = this.filteredListEnProduccion.length;

      // Suma total de todos los arrays
      this.sumatorioTotal = totalCoinciden + totalNoCoinciden + totalNuevaListaProduccion + this.sumatorioArrayDAtaReservaVLC;

      // Resultados de las sumas
      // console.log("Total Coinciden:", totalCoinciden);
      // console.log("Total No Coinciden:", totalNoCoinciden);
      // console.log("Total Nueva Lista Producción:", totalNuevaListaProduccion);
      // console.log("Suma Total:", this.sumatorioTotal);



      // this.sumatorioArraylistEnProduccion = this.filteredListEnProduccion.length; // de momento no se puede usar hasta que no se filtre

      // console.log('this.sumatorioArraylistEnProduccion', this.sumatorioArraylistEnProduccion);
      // console.log('this.sumatorioArraylistTransito', this.sumatorioArraylistTransito);
      // console.log('this.sumatorioArraylistEnReparacion', this.sumatorioArraylistEnReparacion);
      // console.log('this.sumatorioArrayDAtaReservaVLC', this.sumatorioArrayDAtaReservaVLC);

      // this.sumatorioTotal = this.sumatorioArraylistEnProduccion + this.sumatorioArraylistEnReparacion + this.sumatorioArraylistTransito + this.sumatorioArrayDAtaReservaVLC;

      this.updatePagination('VLC');
      this.updatePagination('Produccion');
      this.updatePagination('Reparacion');
      this.updatePagination('Transito');
      this.updatePagination('Totales');

    }, 500);





  }




  compareDates(date1, date2) {
    // Normalizamos las fechas quitando la parte de tiempo si está presente
    const normalizeDate = (date) => {
      const parsedDate = new Date(date);
      // Si la fecha tiene una parte de tiempo, la eliminamos
      const normalizedDate = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate());
      return normalizedDate;
    };

    const normalizedDate1 = normalizeDate(date1);
    const normalizedDate2 = normalizeDate(date2);

    return normalizedDate1 < normalizedDate2;
  }





  exportexcel(opt?: any): void {

    // cssancho@sanitas.es

    let title = 'export-json';
    let fileName = 'Export_json.xlsx';
    // console.log('opt ', opt);


    switch (opt) {

      case 'Reparacion':

        this.datosPrepExcel = this.filteredListEnReparacion.map(obj => ({
          'CECO': obj.ceco,
          'CLÍNICA': obj.RazonSocial,
          'REFERENCIA': obj.Referencia,
          'NÚMERO SERIE': obj.NumeroSerie,
          'DESCRIPCIÓN ARTÍCULO': obj.DescripcionArticulo,
        }));

        fileName = 'ExcelReparacion.xlsx';

        break;

      case 'Produccion':

        this.datosPrepExcel = this.filteredListEnProduccion.map(obj => ({
          'CECO': obj.ceco,
          'CLÍNICA': obj.RazonSocial,
          'REFERENCIA': obj.Referencia,
          'NÚMERO SERIE': obj.NumeroSerie,
          'DESCRIPCIÓN ARTÍCULO': obj.DescripcionArticulo,
        }));

        fileName = 'ExcelProduccion.xlsx';

        break;

      case 'VLC':

        this.datosPrepExcel = this.filteredArrayDataReservaVLC.map(obj => ({
          'CÓDIGO ARTÍCULO': obj.CodigoArticulo,
          'DESCRIPCIÓN ARTÍCULO': obj.DescripcionArticulo,
          'UNIDADES': obj.UnidadSaldo,
        }));

        fileName = 'ExcelStockUrgencia.xlsx';

        break;

      case 'Transito':

        this.datosPrepExcel = this.filteredListEnTransito.map(obj => ({
          'CÓDIGO ARTÍCULO': obj.CodigoArticulo,
          'DESCRIPCIÓN ARTÍCULO': obj.DescripcionArticulo,
          'UNIDADES': obj.UnidadSaldo,
        }));

        fileName = 'ExcelTransito.xlsx';

        break;


    }


    // console.log('this.datosPrepExcel ', this.datosPrepExcel);

    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.datosPrepExcel);
    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataFromDatabase);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, title);
    /* save to file */
    XLSX.writeFile(wb, fileName);

  }


  async cargandodatos(mensaje) {

    // console.log('mensaje', mensaje);

    this.loading = await this.loadingCtrl.create({
      spinner: 'dots',
      message: mensaje, // Puedes ajustar la duración según sea necesario
    });

    await this.loading.present();
  }



}