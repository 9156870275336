import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { StorageService } from '../../../servicios/storage.service';
import { Todo } from '../../../interface/usuario';
import { ProductosService } from '../../../servicios/productos.service';



@Component({
  selector: 'app-historialasistencia',
  templateUrl: './historialasistencia.page.html',
  styleUrls: ['./historialasistencia.page.scss'],
})
export class HistorialasistenciaPage implements OnInit {

  validado: any;
  validar: any;


  newarray_CIR: any = [];
  newarray_CLI: any = [];
  newarray_ESM: any = [];
  newarray_LAB: any = [];
  newarray_UES: any = [];
  newarray_OSS: any = [];


  menuproductos: boolean = false;
  menuasistencia: boolean = false;
  menuhistorialfacturas: boolean = false;
  menuhistorialasistenciatecnica: boolean = true;
  todos: Todo[];
  copiaArrayAsistencias: any[];
  semaforo: any[];
  id: number;


  estadosTimeline = [
    { id: 'sin-recibir', nombre: 'Sin Recibir', estado: false },
    { id: 'recibido', nombre: 'Recibido', estado: false },
    { id: 'revision', nombre: 'Revisión', estado: false },
    { id: 'pendiente-aceptar', nombre: 'Pendiente de Aceptar', estado: false },
    { id: 'pendiente-pago', nombre: 'Pendiente de Pago', estado: false },
    { id: 'reparando', nombre: 'Reparando', estado: false },
    { id: 'enviado', nombre: 'Enviado', estado: false }
  ];


  constructor(
    public navCtrl: NavController,
    private _storage: StorageService,
    private _misproductos: ProductosService,

  ) {

  }

  async ngOnInit() {

    await this._storage.validarusuario().then((result) => {
      this.validado = result;
    });

    await this._storage.validar().then((result) => {
      this.validar = result;
    });

    if (this.validado) {


      if (this.validar == "si") {

        this.todos = await this._storage.read();
        await this.iniciarlistado();

      } else {

        await this._storage.createTodo();
        this.todos = await this._storage.read();
        await this.iniciarlistado();

      }


    } else {
      this._storage.val = "no";
      // console.log(this.validado);
      this._storage.cerrarsesion();
      this.navCtrl.navigateRoot('/home');

    }

  }

  solicitarasistencia() {
    this.navCtrl.navigateForward('/asistenciatecnica');
  }


  micuenta() {
    this.navCtrl.navigateForward('/micuenta');
  }

  shop() {
    this.navCtrl.navigateForward('/shop');
  }


  async iniciarlistado() {

    let cif = this.todos[0]['CIFDNI'];
    this.id = this.todos[0]['id'];

    await this._misproductos.listarAsistenciaTecnica(cif).subscribe(async (ans) => {


      this.copiaArrayAsistencias = [];
      this.copiaArrayAsistencias.push(...ans['asistencias']);

      this.semaforo = [];
      this.semaforo.push(...ans['semaforo']);

      // console.log(this.copiaArrayAsistencias);
      // console.log(this.semaforo);

      // console.log(ans['semaforo1']);



      this.copiaArrayAsistencias.forEach(asistencia => {


        this.estadosTimeline = [
          { id: 'sin-recibir', nombre: 'Sin Recibir', estado: false },
          { id: 'recibido', nombre: 'Recibido', estado: false },
          { id: 'revision', nombre: 'Revisión', estado: false },
          { id: 'pendiente-aceptar', nombre: 'Pendiente de Aceptar', estado: false },
          { id: 'pendiente-pago', nombre: 'Pendiente de Pago', estado: false },
          { id: 'reparando', nombre: 'Reparando', estado: false },
          { id: 'enviado', nombre: 'Enviado', estado: false }
        ];




        const semaforoItem = this.semaforo.find(semaforo =>
          semaforo.CodigoArticulo === asistencia.Referencia &&
          semaforo.NumeroSerieLc === asistencia.NumeroSerie
        );

        console.log(semaforoItem?.CodigoArticulo);
        console.log(semaforoItem?.NumeroSerieLc);
        console.log(semaforoItem?.StatusParteLc);


        if (semaforoItem) {
          console.log("fechaSolicitud", semaforoItem.Fecha.split(' ')[0]);
          console.log("fechaAsistencia", asistencia.FechaSolicitud);

          const fechaAsistencia = new Date(asistencia.FechaSolicitud);
          const fechaSolicitud = new Date(semaforoItem.Fecha.split(' ')[0]);

          // Verificar si las fechas son válidas
          if (!isNaN(fechaSolicitud.getTime()) && !isNaN(fechaAsistencia.getTime())) {


            // Verificar todas las condiciones requeridas
            if (fechaAsistencia < fechaSolicitud) {

              console.log("ENTRA EN LAS COMPARACIONES");

              // Primera comparación
              if (semaforoItem.StatusParteLc === '1' &&
                (semaforoItem.TecnicoAsignadoLc !== '0020' &&
                  semaforoItem.TecnicoAsignadoLc !== '0005' &&
                  semaforoItem.TecnicoAsignadoLc !== '0051' &&
                  semaforoItem.TecnicoAsignadoLc !== '0052')) {
                this.actualizarEstadoTimeline('recibido', true);
                this.copiaArrayAsistencias = [{ estado: true }];

              }
              // Segunda comparación
              else if (semaforoItem.StatusParteLc === '1' &&
                (semaforoItem.TecnicoAsignadoLc === '0020' ||
                  semaforoItem.TecnicoAsignadoLc === '0005' ||
                  semaforoItem.TecnicoAsignadoLc === '0051' ||
                  semaforoItem.TecnicoAsignadoLc === '0052')) {
                this.actualizarEstadoTimeline('revision', true);
              }
              // Tercera comparación
              else if (semaforoItem.StatusParteLc === '1' &&
                (semaforoItem.TecnicoAsignadoLc === '0020' ||
                  semaforoItem.TecnicoAsignadoLc === '0005' ||
                  semaforoItem.TecnicoAsignadoLc === '0051' ||
                  semaforoItem.TecnicoAsignadoLc === '0052') &&
                semaforoItem.WehadentPendienteAceptar === '-1' &&
                semaforoItem.WehadentComunicadoCliente === '-1') {
                this.actualizarEstadoTimeline('pendiente-aceptar', true);
              }
              // Cuarta comparación
              else if (semaforoItem.StatusParteLc === '4' &&
                (semaforoItem.TecnicoAsignadoLc === '0020' ||
                  semaforoItem.TecnicoAsignadoLc === '0005' ||
                  semaforoItem.TecnicoAsignadoLc === '0051' ||
                  semaforoItem.TecnicoAsignadoLc === '0052') &&
                semaforoItem.WehadentPendienteAceptar === '-1' &&
                semaforoItem.WehadentComunicacionCliente === '-1' &&
                semaforoItem.WehadentPresupuestoPagado === '0') {
                this.actualizarEstadoTimeline('pendiente-pago', true);
              }
              // Quinta comparación
              else if (semaforoItem.StatusParteLc === '4' &&
                (semaforoItem.TecnicoAsignadoLc === '0020' ||
                  semaforoItem.TecnicoAsignadoLc === '0005' ||
                  semaforoItem.TecnicoAsignadoLc === '0051' ||
                  semaforoItem.TecnicoAsignadoLc === '0052') &&
                semaforoItem.WehadentPresupuestoPagado === '-1' &&
                semaforoItem.WehadentAcabado === '-1') {
                this.actualizarEstadoTimeline('reparando', true);
              }
              // Sexta comparación
              else if (semaforoItem.StatusParteLc === '2') {
                this.actualizarEstadoTimeline('enviado', true);
              }
              else {
                // Si ninguna de las condiciones anteriores se cumple
                console.log('No se cumple ninguna condición.');

              }
            } else {

              console.log('La fecha de asistencia no es posterior a la fecha de solicitud.');
              if (semaforoItem.StatusParteLc === '2') {
                this.actualizarEstadoTimeline('enviado', true);
              }

              //this.actualizarEstadoTimeline('sin-recibir', true);
            }



          } else {
            // Manejar el caso en el que las fechas no sean válidas
            console.error("La fecha de solicitud o la fecha de asistencia no son válidas.");
            // Actualizar el estado del timeline a 'sin-recibir' en caso de fechas inválidas
            this.actualizarEstadoTimeline('sin-recibir', true);
          }


        } else {
          console.log("semaforoItem es undefined, actualizando estado del timeline a 'false'");
          this.actualizarEstadoTimeline('sin-recibir', true);
        }




        // console.log(this.copiaArrayAsistencias);
        // console.log(this.estadosTimeline);


        asistencia.estados = this.estadosTimeline;
        this.copiaArrayAsistencias.push(...asistencia);

        console.log(this.copiaArrayAsistencias);




      });

      // Retornar true si se encuentra un semáforo correspondiente, false de lo contrario
      // if (semaforoCorrespondiente !== undefined) {
      //   return '';
      // }

    });

    // Aquí tienes tu array con los elementos que retornaron true
    // const arrayResultante = asistenciasFiltradas;

    // Supongamos que ya tienes el array resultante de las asistencias filtradas





  }




  actualizarEstadoTimeline(id: string, nuevoEstado: boolean) {
    const estado = this.estadosTimeline.find(estado => estado.id === id);
    if (estado) {
      estado.estado = nuevoEstado;
    } else {
      console.error(`No se encontró el estado con ID: ${id}`);
    }
  }



}
