import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CountrySelectService } from 'src/app/servicios/country-select.service';
import { MicuentaService } from 'src/app/servicios/micuenta.service';
import { StorageService } from 'src/app/servicios/storage.service';
import { Port, Todo } from '../../../interface/usuario';



interface User {
  id: number;
  sigla: string;
  Pais: string;
}


@Component({
  selector: 'app-creardireccionenes',
  templateUrl: './creardireccionenes.page.html',
  styleUrls: ['./creardireccionenes.page.scss'],
})
export class CreardireccionenesPage implements OnInit {





  user = {
    "RazonSocial": '',
    "Pais": 'España',
    "CodigoSigla": 'CL',
    "Calle": '',
    "Numero": '',
    "Escalera": '',
    "Piso": '',
    "Puerta": '',
    "Letra": '',
    "Poblacion": 'VALENCIA',
    "Provincia": '',
    "CodPostal": '',
    "id": '',
    "sigla": ''
  }



  listadopaises: any = {
    datos: ""
  };


  listadoprovincias: any = {
    datos: ""
  };

  listadosiglas: any = {
    datos: ""
  };

  public todos: Array<Todo> = [];
  datos: Todo[] = [];
  copiaArrayArchivos: Todo[] = [];

  copiaArrayArchivosprovincias: any = [];
  copiaArrayArchivossiglas: any = [];
  copiaArrayArchivoslistadopaises: any = [];

  currentColor: any = { id: '1' };

  nombreprovincia: any = [];

  selectcategory: any = "ESPAÑA";
  selectcategory1: any;

  nombresigla: any = ["CL"];

  razonsocial: any;
  cif: string;
  respuesta: any;



  // AUX BUG ----------------------------------------------------------------------------------------------------------------
  paises = Array();
  pais_default: string;
  pais_text_default: string;

  provincias = Array();
  provincia_default: string;
  provincia_text_default: string;

  siglas = Array();
  sigla_default: string;
  sigla_text_default: string;

  aux_bug: boolean = false;
  aux_prov: string;
  aux_sigla: string;
  validado: any;
  validar: any;
  desdemenucuenta: any;

  // AUX BUG ----------------------------------------------------------------------------------------------------------------

  // email: any;
  // modal: HTMLIonModalElement;
  // telefono: any;
  // id: number;
  // Nombre: any;
  // Apellidos: any;
  // Calle: any;
  // Numero: any;
  // Puerta: any;
  // Piso: any;
  // Escalera: any;
  // Letra: any;
  // Provincia: any;
  // CodPostal: any;
  // Poblacion: any;
  // Pais: any;
  // CodigoSigla: any;





  constructor(
    private loadingController: LoadingController,
    public navCtrl: NavController,
    private toastController: ToastController,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private _storage: StorageService,
    private _micuenta: MicuentaService,
    private countrySelect: CountrySelectService
  ) {

  }





  async ngOnInit() {

    // console.log(this.navParams.get('desdemenucuenta'));
    this.desdemenucuenta = this.navParams.get('desdemenucuenta');


  }


  async ionViewWillEnter() {

    // SELECTS DIRECCIONES PAISES
    this.pais_default = "ES-España";
    this.pais_text_default = "España";
    this.provincia_default = "46-VALENCIA/VALÉNCIA";
    this.provincia_text_default = "VALENCIA/VALÉNCIA";
    this.sigla_default = "9-Calle";
    this.sigla_text_default = "Calle";

    // console.log("QUE PAIS ESTAMOS SACANDO DEL PRINCIPIO"+ this.paises);
    // console.log("QUE PAIS ESTAMOS"+this.nombresigla);



    await this.iniciocreardirecciones();
    await this.loadPaises();


  }



  async loadPaises() {
    await this.countrySelect.getPaises()
      .subscribe(resp => {
        this.paises.push(...resp);
      });
  }

  async cambiarPais(pais) {
    let datos_pais = pais.split("-");
    this.pais_default = pais;
    this.pais_text_default = datos_pais[1];
    await this.loadProvincias({ "Pais": datos_pais[0] }, this.aux_bug);
    await this.loadSiglas({ "IdiomaSigla": datos_pais[0] }, this.aux_bug);
    this.aux_bug = true;
  }

  cambiarProvincia(provincia) {
    let datos_provincia = provincia.split("-");
    this.provincia_default = datos_provincia[0] + "-" + datos_provincia[1];
    this.provincia_text_default = datos_provincia[1];
  }

  cambiarSigla(sigla) {
    let datos_sigla = sigla.split("-");
    this.sigla_default = datos_sigla[0] + "-" + datos_sigla[1];
    this.sigla_text_default = datos_sigla[1];
  }

  async loadProvincias(provincias, id?) {
    this.provincias = [];

    let bug = 1;

    await this.countrySelect.getProvincias(provincias)
      .subscribe(resp => {
        if (bug === 1) {
          if (id) {
            this.aux_prov = resp[0];
            this.provincia_default = this.aux_prov['id'] + "-" + this.aux_prov['Provincia'];
            this.provincia_text_default = this.aux_prov['Provincia'];
          }
          bug++;
        }
        this.provincias.push(...resp);
      });
  }


  async loadSiglas(sigla, id?) {
    this.siglas = [];
    let bug = 1;
    await this.countrySelect.getSiglas(sigla)
      .subscribe(resp => {
        if (bug === 1) {
          if (id) {
            this.aux_sigla = resp[0];
            this.sigla_default = this.aux_sigla['id'] + "-" + this.aux_sigla['NombreSigla'];
            this.sigla_text_default = this.aux_sigla['NombreSigla'];
            bug++;
          }
        }
        this.siglas.push(...resp);
      });
  }
  // ----------------------------------------------------------------------------------------------------------------







  async iniciocreardirecciones() {


    //RECOGEMOS LOS DATOS DEL STORAGE TODAS LAS VARIABLES
    await this._storage.read().then((result) => {

      this.datos = result;

      this.copiaArrayArchivos = [];
      this.copiaArrayArchivos.push(... this.datos);


      this.razonsocial = this.copiaArrayArchivos[0]['RazonSocial'];
      this.cif = this.copiaArrayArchivos[0]['CIFDNI'];

      // console.log("this.razonsocial"+this.razonsocial);

    });


    // console.log(this.navParams.get('model_title'));




  }




  OnChange(event): void {

    // console.log(event.target.value);


    this.paises = [event.target.value.Pais];

    // console.log("this.paises"+ this.paises);



  }


  OnChangesiglas(event): void {

    // console.log(event.target.value.NombreSigla);

    this.nombresigla = event.target.value.NombreSigla;


  }

  OnChangeprovincia(event): void {

    // console.log(event.target.value.Provincia);

    this.nombreprovincia = event.target.value.Provincia;

  }





  async anadirdireccion(dato) {



    let sigla = dato.controls.Siglas.value;
    let pais = dato.controls.Pais.value;
    let provincia = dato.controls.Provincia.value;

    if (sigla === this.sigla_default) {

      sigla = this.sigla_text_default;

    }

    if (pais === this.pais_default) {

      pais = this.pais_text_default;

    }

    if (provincia === this.provincia_default) {

      provincia = this.provincia_text_default;

    }



    // console.log(dato.controls.Escalera.value);
    // console.log(dato.controls.Letra.value);

    // console.log(dato.controls.Municipio.value);

    // console.log(dato.controls.Nombrevia.value);
    // console.log(dato.controls.Numero.value);
    // console.log(dato.controls.Pais.value.Pais);
    // console.log(dato.controls.Piso.value);
    // console.log(dato.controls.Provincia.value.Provincia);
    // console.log(dato.controls.Puerta.value);
    //console.log(dato.controls.Siglas.value.NombreSigla);
    // console.log(dato.controls.CodPostal.value);

    const datosusua = {

      "CIFDNI": this.cif,
      "Escalera": dato.controls.Letra.value,
      "Calle": dato.controls.Nombrevia.value,
      "Piso": dato.controls.Piso.value,
      "Puerta": dato.controls.Puerta.value,
      "Letra": dato.controls.Letra.value,
      "CodigoSigla": sigla,
      "Numero": dato.controls.Numero.value,
      "CodPostal": dato.controls.CodPostal.value,
      "Poblacion": dato.controls.Poblacion.value,
      "Provincia": provincia,
      "Pais": pais,
      "TipoDomicilio": "G"

    };




    this._micuenta.anadircuenta(datosusua).subscribe(async (ans: []) => {


      this.respuesta = ans;

      // console.log(this.respuesta);

      if (this.respuesta == 'error') {


        const loading = await this.loadingController.create({
          spinner: null,
          duration: 3000,
          message: this.respuesta,
          translucent: true,
          // cssClass: 'custom-class custom-loading',
          backdropDismiss: true
        });
        await loading.present();

        this.loadingController.dismiss()
          .then((data) => {
            //console.log(data);// Here's your selected user!
          });


      } else {

        const loading = await this.loadingController.create({
          spinner: null,
          duration: 3000,
          message: this.respuesta,
          translucent: true,
          // cssClass: 'custom-class custom-loading',
          backdropDismiss: true
        });
        await loading.present();

        this.loadingController.dismiss();



      }


      this.modalCtrl.dismiss()
        .then((pep) => {
          if (this.desdemenucuenta == "yes") {

            window.location.reload();// Here's your selected user!

            //this.navCtrl.navigateRoot('/');

          } else {
            this.ionViewWillEnter();
          }

        });


    });



  }


  closemodal() {

    this.modalCtrl.dismiss((data) => {
      //console.log(data);
    });

  }







}
